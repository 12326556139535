import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: {
    id: "en",
    flag: "united-states-of-america",
    title: "English",
    code: "EN",
  },
  languages: [
    {
      id: "en",
      flag: "united-states-of-america",
      code: "EN",
      title: "English",
    },
    {
      id: "es",
      flag: "spanish",
      title: "spanish",
      code: "ES",
    },
  ],
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage(state, action) {
      return { ...state, language: action.payload };
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
