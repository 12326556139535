import React, { useEffect, useState } from "react";
import GreenRadioInput from "../../../../components/Form/GreenRadioInput";
import BlueButton from "../../../../components/Form/BlueButton";
import ProfileSetup from "../../../../apis/provider/ProfileSetup";
import Loading from "../../../../components/general/Loading";
import { useTranslation } from "react-i18next";

export default function ServicesOffer() {
  const { t } = useTranslation();
  const [checkMassageOptions, setCheckMassageOptions] = useState(true);
  const [allTypes, setAllTypes] = useState({
    all: false,
    all_massage_type: false,
  });
  const [selectedServicesAndMassageType, setSelectedServicesAndMassageType] =
    useState({
      service_ids: [],
      massage_type_ids: [],
    });
  const [servicesAndMassageTypes, setServiceAndMassageTypes] = useState({
    massage_types: [],
    services: [],
  });

  const { isLoading, getServicesAndMassageTypes, sendServicesAndMassageTypes } =
    ProfileSetup();

  const fetchServicesAndMassageType = async () => {
    const response = await getServicesAndMassageTypes();
    setServiceAndMassageTypes(response);
  };

  const handleSubmit = async () => {
    await sendServicesAndMassageTypes(selectedServicesAndMassageType);
  };

  const handleChange = (e, type) => {
    const value = Number(e.target.value);

    const service_ids = selectedServicesAndMassageType.service_ids;
    const massage_types_ids = selectedServicesAndMassageType.massage_type_ids;

    if (type == "all") {
      !allTypes.all
        ? setSelectedServicesAndMassageType({
            service_ids: servicesAndMassageTypes.services.map(
              (service) => service.id
            ),
            massage_type_ids: servicesAndMassageTypes.massage_types.map(
              (massage_type) => massage_type.id
            ),
          })
        : setSelectedServicesAndMassageType({
            service_ids: [],
            massage_type_ids: [],
          });
    } else if (type == "all_massage_type") {
      !allTypes.all_massage_type
        ? setSelectedServicesAndMassageType({
            ...selectedServicesAndMassageType,
            massage_type_ids: servicesAndMassageTypes.massage_types.map(
              (massage_type) => massage_type.id
            ),
          })
        : setSelectedServicesAndMassageType({
            ...selectedServicesAndMassageType,
            massage_type_ids: [],
          });
    } else if (type == "service") {
      const service_flag = service_ids.includes(value);

      if (!service_flag) {
        setSelectedServicesAndMassageType({
          ...selectedServicesAndMassageType,
          service_ids: [...service_ids, value],
        });
      } else {
        const remainingValues =
          selectedServicesAndMassageType.service_ids.filter(
            (item) => item != value
          );
        setSelectedServicesAndMassageType({
          ...selectedServicesAndMassageType,
          service_ids: remainingValues,
        });
      }
    } else if (type == "massage_type") {
      const massage_flag = massage_types_ids.includes(value);
      if (!massage_flag) {
        setSelectedServicesAndMassageType({
          ...selectedServicesAndMassageType,
          massage_type_ids: [...massage_types_ids, value],
        });
      } else {
        const remainingValues =
          selectedServicesAndMassageType.massage_type_ids.filter(
            (item) => item != value
          );
        setSelectedServicesAndMassageType({
          ...selectedServicesAndMassageType,
          massage_type_ids: remainingValues,
        });
      }
    }
  };

  const checkedAllFlag = () => {
    const services_flag =
      selectedServicesAndMassageType.service_ids.length ==
      servicesAndMassageTypes.services.length;
    const massage_flag =
      selectedServicesAndMassageType.massage_type_ids.length ==
      servicesAndMassageTypes.massage_types.length;

    if (
      selectedServicesAndMassageType.service_ids.length > 0 &&
      services_flag &&
      massage_flag
    ) {
      setAllTypes({
        all: true,
        all_massage_type: true,
      });
    } else if (
      selectedServicesAndMassageType.massage_type_ids.length > 0 &&
      massage_flag
    ) {
      setAllTypes({
        all: false,
        all_massage_type: true,
      });
    } else {
      setAllTypes({
        all: false,
        all_massage_type: false,
      });
    }
  };
  useEffect(() => {
    checkedAllFlag();
  }, [selectedServicesAndMassageType]);

  useEffect(() => {
    fetchServicesAndMassageType();
  }, []);

  return (
    <div className="servicesOffer">
      <div className="servicesOffer__container">
        <img
          src={require("../../../../assets/logo.jpeg")}
          alt="bodywerx.com"
          width="200"
        />
        <header>
          <h3>{t("providers.services.selectServices")}</h3>
          <h5>{t("providers.services.chooseServices")}</h5>
        </header>
        <div className="servicesOffer__container__body">
          <div className="servicesOffer__container__body__left">
            <div className="servicesOffer__container__body__left__flexImageLeft">
              <img src={require("../../../../assets/grooming.jpeg")} alt="" />
            </div>
            <div className="servicesOffer__container__body__left__flexImageRight">
              <img src={require("../../../../assets/provider.jpeg")} alt="" />
            </div>
            <div className="servicesOffer__container__body__left__flexImageLeft">
              <img src={require("../../../../assets/trainer.jpeg")} alt="" />
            </div>
          </div>

          <div className="servicesOffer__container__body__right">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <div className="servicesOffer__container__body__right__radioInput">
                  <input
                    name="all"
                    type="radio"
                    onClick={(e) => handleChange(e, "all")}
                    // value="all"
                    checked={allTypes.all}
                  />
                  <label>Select All</label>
                </div>
                {servicesAndMassageTypes.services.map((service) => {
                  return (
                    <>
                      <div className="greenRadioInput">
                        <input
                          name={
                            service.name.toLowerCase() == "massage"
                              ? "all_massage_type"
                              : `service_${service.id}`
                          }
                          type="radio"
                          checked={
                            service.name.toLowerCase() == "massage"
                              ? allTypes.all_massage_type
                              : selectedServicesAndMassageType.service_ids.includes(
                                  service.id
                                )
                          }
                          onClick={(e) =>
                            handleChange(
                              e,
                              service.name.toLowerCase() == "massage"
                                ? "all_massage_type"
                                : "service"
                            )
                          }
                          value={service.id}
                        />
                        <label>{service.name}</label>
                      </div>
                      {service.name.toLowerCase() == "massage" &&
                        servicesAndMassageTypes.massage_types.map(
                          (massage_type) => {
                            return (
                              <div className="servicesOffer__container__body__right__radioInput servicesOffer__container__body__right__radioInput2">
                                <input
                                  name={`massage_${massage_type.id}`}
                                  type="radio"
                                  checked={selectedServicesAndMassageType.massage_type_ids.includes(
                                    massage_type.id
                                  )}
                                  onClick={(e) =>
                                    handleChange(e, "massage_type")
                                  }
                                  value={massage_type.id}
                                />
                                <label>{massage_type.name}</label>
                              </div>
                            );
                          }
                        )}
                    </>
                  );
                })}
                {/* <GreenRadioInput label={"Manscaping"} />
                <GreenRadioInput label={"Personal Training"} />
                {}
                <GreenRadioInput label={"Massage Therapy"} /> */}
                {/* {servicesAndMassageTypes.massage_types.length > 0 && (
                  <>
                    <div className="greenRadioInput">
                      <input
                        name="all_massage_type"
                        type="radio"
                        onClick={(e) => handleChange(e, "all_massage_type")}
                        // value="all_massage_type"
                        checked={allTypes.all_massage_type}
                      />
                      <label>Massage Therapy</label>
                    </div>

                    {servicesAndMassageTypes.massage_types.map(
                      (massage_type) => {
                        return (
                          <div className="servicesOffer__container__body__right__radioInput servicesOffer__container__body__right__radioInput2">
                            <input
                              name={`massage_${massage_type.id}`}
                              type="radio"
                              checked={selectedServicesAndMassageType.massage_type_ids.includes(
                                massage_type.id
                              )}
                              onClick={(e) => handleChange(e, "massage_type")}
                              value={massage_type.id}
                            />
                            <label>{massage_type.name}</label>
                          </div>
                        );
                      }
                    )}
                  </>
                )} */}
              </>
            )}
          </div>
        </div>
        <div className="servicesOffer__container__nextButton">
          <BlueButton
            title={"Next"}
            onClick={handleSubmit}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
