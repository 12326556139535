import { store } from "../../redux/store";
import { clientLogoutSuccess } from "../../redux/slices/auth/client";

export const onError = function (error, showToast) {
  if (error.response) {
    if (error.response.status == 401 || error.response.status == 404) {
      store.dispatch(clientLogoutSuccess());
      showToast({ message: "Unauthorized User!", flag: "error" });
      return;
    }
    if (error.response.data)
      showToast({ message: error.response.data.message, flag: "error" });
    return;
  } else {
    console.error("Error Message:", error.message);
  }
  return error;
};

export const onSuccess = function (response, showToast, message) {
  showToast({ message: message, flag: "success" });
  return response.data;
};

export const formDataToObject = (formData) => {
  const object = {};
  formData.forEach((value, key) => {
    object[key] = value;
  });
  return object;
};

function cleanObject(obj) {
  const cleanedObject = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null && obj[key] !== undefined) {
      cleanedObject[key] = obj[key];
    }
  });

  return cleanedObject;
}

export function objectToQueryString(obj) {
  const cleanedObject = cleanObject(obj);
  const queryString = new URLSearchParams(cleanedObject).toString();
  return queryString;
}
