import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../context/ToastContext";
import { SERVER_API } from "../../helpers/variable";
import { onSuccess, onError } from "./request";
import { store } from "../../redux/store";
import {
  clientLogoutSuccess,
  clientData,
} from "../../redux/slices/auth/client";
import { useSelector } from "react-redux";

export default function ProfileApis() {
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const { language } = useSelector((state) => state.i18n);
  const navigate = useNavigate();
  const { showToast } = useToast();

  async function updatePersonalInformation(client) {
    setIsProfileLoading(true);
    const formData = new FormData();
    for (const property in client) {
      if (property == "profile_image") {
        formData.append(property, client[property]);
      } else {
        formData.append(`client[${property}]`, client[property]);
      }
    }

    return await axios
      .put(
        `${SERVER_API}/clients/profile/update_personal_information?locale=${language.id}`,
        formData,
        {
          headers: {
            Authorization: Cookies.get("client-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Updated client information!");
        setIsProfileLoading(false);
        store.dispatch(clientData({ client: response.data }));
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsProfileLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function updateClientAddress(address) {
    return await axios
      .put(
        `${SERVER_API}/clients/profile/update_address?locale=${language.id}`,
        { client: { ...address } },
        {
          headers: {
            Authorization: Cookies.get("client-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Updated client address!");
        return response.data;
      })
      .catch((error) => {
        onError(error, showToast);
      });
  }

  async function updatePassword(params) {
    setIsProfileLoading(true);

    const response = await axios
      .put(
        `${SERVER_API}/clients/profile/update_password`,
        { client: { ...params } },
        {
          headers: {
            Authorization: Cookies.get("client-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Password Updated Successfully!");
        store.dispatch(clientLogoutSuccess());
        navigate("/signin");
        setIsProfileLoading(false);
      })
      .catch((error) => {
        onError(error, showToast);
        setIsProfileLoading(false);
      });
  }

  return {
    updatePersonalInformation,
    updateClientAddress,
    updatePassword,
    isProfileLoading,
  };
}
