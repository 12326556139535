import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import BasicModalOptions from "./BasicModalOptions";
import BlueButton from "../../../../../components/Form/BlueButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  p: 2,
  borderRadius: "1rem",
};

export default function BasicCardModal({ open, handleClose, levelPackage }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedPrice, setSelectedPrice] = useState("1");

  return (
    <main>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <section className="basicCardModal">
            <header className="basicCardModal__header">
              <h3>{t("providers.myLevel.basicLevel")}</h3>
            </header>
            <aside className="basicCardModal__body">
              <BasicModalOptions levelPackage={levelPackage} />
              <section className="basicCardModal__body__plans">
                <header className="basicCardModal__body__plans__header">
                  <h3>{t("providers.myLevel.plan")}</h3>
                </header>
                <section className="basicCardModal__body__plans__box">
                  <div className="basicCardModal__body__plans__box__radioBox">
                    <input
                      type="radio"
                      value="1"
                      name="price"
                      checked={selectedPrice === "1"}
                      onChange={(e) => setSelectedPrice(e.target.value)}
                    />
                    <label>
                      ${levelPackage.duration_frequency["1"]}/
                      {t("providers.myLevel.month")}
                    </label>
                  </div>
                  <div className="basicCardModal__body__plans__box__radioBox">
                    <input
                      type="radio"
                      value="3"
                      name="price"
                      checked={selectedPrice === "3"}
                      onChange={(e) => setSelectedPrice(e.target.value)}
                    />
                    <label>
                      ${levelPackage.duration_frequency["3"]}/3{" "}
                      {t("providers.myLevel.month")}
                    </label>
                  </div>
                  <div className="basicCardModal__body__plans__box__radioBox">
                    <input
                      type="radio"
                      value="6"
                      name="price"
                      checked={selectedPrice === "6"}
                      onChange={(e) => setSelectedPrice(e.target.value)}
                    />
                    <label>
                      ${levelPackage.duration_frequency["6"]}/6{" "}
                      {t("providers.myLevel.month")}
                    </label>
                  </div>
                </section>
              </section>
              <div>
                <div className="basicCardModal__body__divider">
                  <div>
                    <span>{t("providers.myLevel.or")}</span>
                  </div>
                </div>
                <div className="basicCardModal__body__offerContainer">
                  <div>
                    <input
                      type="radio"
                      value="12"
                      name="price"
                      checked={selectedPrice === "12"}
                      onChange={(e) => setSelectedPrice(e.target.value)}
                    />
                    <label>
                      <p>
                        ${levelPackage.duration_frequency["12"]}{" "}
                        {t("providers.myLevel.perYear")}
                      </p>
                      <span>{t("providers.myLevel.oneTimePayment")}</span>
                    </label>
                    <span className="basicCardModal__body__offerContainer__saving">
                      {t("providers.myLevel.saving")} <br /> 10%
                    </span>
                  </div>
                </div>
                <div className="basicCardModal__body__saveButton">
                  <div>
                    <BlueButton
                      title={t("providers.myLevel.continue")}
                      onClick={() =>
                        navigate(
                          `/provider/payment/price/${levelPackage.duration_frequency[selectedPrice]}/${levelPackage.id}/${selectedPrice}`
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </aside>
          </section>
        </Box>
      </Modal>
    </main>
  );
}
