import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./routes";
import ProviderPrivateRoute from "./ProviderPrivateRoute";
import PrivateRoute from "./PrivateRoute";
import ProviderProfileSetupRoute from "./ProviderProfileSetupRoute";
import ClientPrivateRoute from "./ClientPrivateRoute";
import ClientProfileSetupRoute from "./ClientProfileSetupRoute";
import { setLocation } from "../redux/slices/locationSlice";
import { useDispatch } from "react-redux";

const Router = () => {
  const dispatch = useDispatch();
  // const savedLocation = (position) => {
  //   const latitude = position.coords.latitude;
  //   const longitude = position.coords.longitude;
  //   dispatch(setLocation({ latitude, longitude }));
  // };

  // useEffect(() => {
  //   if (!navigator.geolocation) {
  //     alert("Geolocation is not supported by your browser");
  //     return;
  //   }
  //   navigator.geolocation.getCurrentPosition(savedLocation, (error) => {
  //     alert(error.message);
  //   });
  // }, []);

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route) => (
          <Route
            path={route.path}
            element={
              route.privateRouting == "provider" ? (
                <ProviderPrivateRoute>
                  {<route.component />}
                </ProviderPrivateRoute>
              ) : route.privateRouting == "providerProfileSetup" ? (
                <ProviderProfileSetupRoute>
                  {" "}
                  {<route.component />}
                </ProviderProfileSetupRoute>
              ) : route.privateRouting == "client" ? (
                <ClientPrivateRoute> {<route.component />}</ClientPrivateRoute>
              ) : route.privateRouting == "clientProfileSetup" ? (
                <ClientProfileSetupRoute>
                  {" "}
                  {<route.component />}
                </ClientProfileSetupRoute>
              ) : (
                <route.component />
              )
            }
            key={route.path}
          >
            {route.subRoutes?.map((sub) => (
              <Route
                path={sub.path}
                element={<sub.component />}
                key={sub.path}
              />
            ))}
          </Route>
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
