import React, { useEffect, useState } from "react";
import "./App.css";
import Router from "./routing/Router";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setLocation } from "./redux/slices/locationSlice";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClient = new QueryClient();

function App() {
  const { language } = useSelector((state) => state.i18n);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language.id);
  }, [language]);

  return (
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  );
}

export default App;
