import * as React from "react";
import { Box, Drawer, List, Divider, IconButton } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { setSidebarId } from "../../../redux/slices/sidebarSlice";
import { sidebarItems } from "../../../redux/slices/sidebarSlice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

function ProviderNavDrawer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const sideBarId = useSelector((state) => state.sidebar.sideBarId);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleClick = (item) => {
    dispatch(setSidebarId(item.id));
    navigate(item.path);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <img
          src={require("../../../assets/logo.jpeg")}
          alt=""
          style={{ width: "200px", objectFit: "cover", padding: "2rem 0" }}
        />
      </List>
      <Divider />
      <section className="drawer__sidebar__section">
        {sidebarItems.map((item, index) => (
          <div
            key={item.id}
            className="drawer__sidebar__section__myprofile"
            onClick={() => handleClick(item)}
            style={{
              background: item.id === sideBarId ? "#476485" : "",
              borderRadius: "6px",
            }}
          >
            <div className="drawer__sidebar__section__myprofile__icon">
              {item.icon}
            </div>
            <span className="drawer__sidebar__section__myprofile__name">
              {t(item.name)}
            </span>
          </div>
        ))}
      </section>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)}>
            <MenuIcon
              style={{
                color: "#7FC550",
              }}
            />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

export default ProviderNavDrawer;
