import React, { useState, useEffect } from "react";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import BlueButton from "../../../components/Form/BlueButton";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

export default function PhoneVerification({
  code,
  setCode,
  handleSubmit,
  resendVerificationApi,
  isLoading,
}) {
  const { t } = useTranslation();
  const [timer, setTimer] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);

  const resendVerifcation = async () => {
    setTimer(60);
    setIsDisabled(true);
    await resendVerificationApi();
  };

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setIsDisabled(false);
    }

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    resendVerifcation();
  }, []);

  return (
    <div className="verificationCode">
      <div className="verificationCode__resend">
        <span>{timer}</span>
        <Button
          size="small"
          color="success"
          disabled={isDisabled}
          onClick={resendVerifcation}
        >
          Resend
        </Button>
      </div>
      <div className="verificationCode__iconBox">
        <PhoneIphoneOutlinedIcon className="verificationCode__iconBox__phoneIcon" />
      </div>
      <h3>{t("client.phoneVerification.account")}</h3>
      <p>{t("client.phoneVerification.verficationcode")}</p>
      <input
        type="number"
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      <div className="verificationCode__verifyButton">
        <BlueButton
          title={t("client.phoneVerification.next")}
          onClick={handleSubmit}
          loading={isLoading}
        />
      </div>
    </div>
  );
}
