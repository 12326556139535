import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

const BlueButton = ({ title, onClick, loading,type }) => {
  return (
    <LoadingButton
      loading={loading}
      loadingPosition="start"
      className="blueButton"
      variant="contained"
      onClick={onClick}
      type={type}
    >
      {title}
    </LoadingButton>
  );
};

export default BlueButton;
