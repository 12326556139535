import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import ClientPage from "../../../../components/PageWrapper/ClientPage";
import Card from "../Home/components/Card";
import FavouriteApis from "../../../../apis/client/favouriteApis";
import BodyWerxLoading from "../../../../components/general/BodyWerxLoading";
import { useTranslation } from "react-i18next";

export default function Favourite() {
  const { isFavouriteLoading, getFavourites } = FavouriteApis();
  const { t } = useTranslation();
  const [favouriteAds, setFavouriteAds] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchFavourite = async () => {
    const response = await getFavourites();
    if (response.data) {
      setFavouriteAds(response.data);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  useEffect(() => {
    fetchFavourite();
  }, [refetch]);

  if (isFavouriteLoading) return <BodyWerxLoading />;

  return (
    <ClientPage>
      <Box className="favourite">
        {favouriteAds.length == 0 ? (
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "400px",
              color: "#193D66",
              width: "100%",
            }}
          >
            {t("client.title.noFavouriteAds")}
          </h1>
        ) : (
          favouriteAds.map((ad) => {
            return (
              <Card
                ad={ad}
                key={ad.id}
                favourite={true}
                setRefetch={setRefetch}
              />
            );
          })
        )}
      </Box>
    </ClientPage>
  );
}
