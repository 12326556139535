import React, { useState } from "react";
import ProfileInformation from "./ProfileInformation";
import { useSelector } from "react-redux";
import ClientPage from "../../../../components/PageWrapper/ClientPage";

export default function Profile() {
  const { client } = useSelector((state) => state.clientAuth);
  const [profile, setProfile] = useState(client);

  return (
    <ClientPage>
      <main className="clientProfile">
        <section className="clientProfile__container">
          <ProfileInformation profile={profile} setProfile={setProfile} />
        </section>
      </main>
    </ClientPage>
  );
}
