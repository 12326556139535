import { useState } from "react";
import { SERVER_API } from "../helpers/variable";
import axios from "axios";
import { useToast } from "../context/ToastContext";
import { useSelector } from "react-redux";

export default function GeneralApis() {
  const { showToast } = useToast();
  const [isApiLoading, setIsApiLoading] = useState(false);
  const { language } = useSelector((state) => state.i18n);

  async function getDemographics() {
    setIsApiLoading(true);
    return await axios
      .get(`${SERVER_API}/demographics`)
      .then((response) => response)
      .then((response) => {
        setIsApiLoading(false);
        showToast("Demographics data is fetched!", "success");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsApiLoading(false);

        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function sendSupportEmail(support) {
    setIsApiLoading(true);
    await axios
      .get(
        `${SERVER_API}/send_support_email?locale=${language.id}&email=${support.email}&name=${support.name}&subject=${support.subject}&body=${support.body}`
      )
      .then((response) => {
        setIsApiLoading(false);
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsApiLoading(false);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  return {
    getDemographics,
    isApiLoading,
    sendSupportEmail,
  };
}
