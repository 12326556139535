import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function PhotoTab({ ad_photos }) {
  const { t } = useTranslation();
  return (
    <section className="myPhoto__container">
      <aside className="myPhoto__container__body">
        {ad_photos.length > 0 ? (
          ad_photos.map((photo, index) => (
            <figure className="myPhoto__container__body__image">
              <img src={photo.image_url} alt="" />
            </figure>
          ))
        ) : (
          <h3>{t("client.title.NoPhotoforthisAd")}</h3>
        )}
      </aside>
    </section>
  );
}
