import React, { useState } from "react";
import CardOptions from "./CardOptions";
import { useTranslation } from "react-i18next";
import TryUsModal from "./TryUsModal";

const TryUsCard = ({ levelPackage }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <section className="selectLevel__body__cards__card">
      <TryUsModal
        handleClose={handleClose}
        open={open}
        levelPackage={levelPackage}
      />
      <h3 className="selectLevel__body__cards__card__heading">
        {t("providers.myLevel.trylevel")}
      </h3>
      <section
        className="selectLevel__body__cards__card__body"
        onClick={handleOpen}
      >
        <aside className="selectLevel__body__cards__card__body__textBox">
          <CardOptions
            description={t("providers.myLevel.uploadImages", {
              images_limit: levelPackage.images_limit,
            })}
          />
          <CardOptions
            description={t("providers.myLevel.uploadVideos", {
              videos_limit: levelPackage.video_count,
            })}
          />
          {/* <CardOptions description={t("providers.myLevel.availabilityFlag")} /> */}
          <CardOptions
            description={t("providers.myLevel.travelAd", {
              travel_ad: levelPackage.travel_ad,
            })}
          />
          <CardOptions
            description={t("providers.myLevel.localAd", {
              local_ad: levelPackage.local_ad,
            })}
          />
          <CardOptions
            description={t("providers.myLevel.serviceHourSchedule", {
              service_hour_schedule: levelPackage.service_hour_schedule,
            })}
          />
          <CardOptions
            description={t("providers.myLevel.serviceAndMassageType", {
              service_and_massage_types: levelPackage.service_and_massage_types,
            })}
          />
          <CardOptions description={t("providers.myLevel.emailSupport")} />
        </aside>
        <section className="selectLevel__body__cards__card__body__trial">
          <h5>{t("providers.myLevel.trial")}</h5>
          <h5>{t("providers.myLevel.avaialable")}</h5>
          <h5>{t("providers.myLevel.account")}</h5>
        </section>
        <div className="selectLevel__body__cards__card__body__divider"></div>
        <div className="selectLevel__body__cards__card__body__trial2">
          <h5>{t("providers.myLevel.promo")}</h5>
          <h5>{t("providers.myLevel.tryus")}</h5>
        </div>
      </section>
    </section>
  );
};

export default TryUsCard;
