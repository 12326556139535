import React from "react";
import { useTranslation } from "react-i18next";
import ClientPage from "../../../../components/PageWrapper/ClientPage";

export default function PrivacyPolicy() {
  const { t } = useTranslation();
  const currentDate = new Date().toLocaleString("en-US", {
    month: "long",
    year: "numeric",
  }); // Format date for display

  return (
    <ClientPage>
      <div className="privacyPolicy">
        <h1>{t("client.privacyPolicy.title")}</h1>
        <div className="privacyPolicy__content">
          <h2>
            {t("client.privacyPolicy.effectiveDate")}: {currentDate}
          </h2>
          <p>{t("client.privacyPolicy.effectiveDateDetail")}</p>

          <h2>{t("client.privacyPolicy.information")}</h2>
          <div className="privacyPolicy__content__heading3">
            <h3>{t("client.privacyPolicy.personalInformation")}</h3>
            <p>{t("client.privacyPolicy.personalInformationDetail")}</p>

            <h3>{t("client.privacyPolicy.usageData")}</h3>
            <p>{t("client.privacyPolicy.usageDataDetail")}</p>

            <h3>{t("client.privacyPolicy.cookies")}</h3>
            <p>{t("client.privacyPolicy.cookiesDetail")}</p>
          </div>

          <h2>{t("client.privacyPolicy.yourInformation")}</h2>
          <p>{t("client.privacyPolicy.yourInformationDetail")}</p>

          <div className="privacyPolicy__content__heading3">
            <h3>{t("client.privacyPolicy.provideAndMaintainWebsite")}</h3>
            <p>{t("client.privacyPolicy.provideAndMaintainWebsiteDetail")}</p>

            <h3>{t("client.privacyPolicy.processTransactions")}</h3>
            <p>{t("client.privacyPolicy.processTransactionsDetail")}</p>

            <h3>{t("client.privacyPolicy.communicateWithYou")}</h3>
            <p>{t("client.privacyPolicy.communicateWithYouDetail")}</p>

            <h3>{t("client.privacyPolicy.respondToInquiries")}</h3>
            <p>{t("client.privacyPolicy.respondToInquiriesDetail")}</p>

            <h3>{t("client.privacyPolicy.analyzeAndImprove")}</h3>
            <p>{t("client.privacyPolicy.analyzeAndImproveDetail")}</p>
          </div>

          <h2>{t("client.privacyPolicy.disclosure")}</h2>
          <p>{t("client.privacyPolicy.disclosureDetail")}</p>

          <div className="privacyPolicy__content__heading3">
            <h3>{t("client.privacyPolicy.serviceProviders")}</h3>
            <p>{t("client.privacyPolicy.serviceProvidersDetail")}</p>

            <h3>{t("client.privacyPolicy.legalRequirements")}</h3>
            <p>{t("client.privacyPolicy.legalRequirementsDetail")}</p>

            <h3>{t("client.privacyPolicy.businessTransfers")}</h3>
            <p>{t("client.privacyPolicy.businessTransfersDetail")}</p>

            <h3>{t("client.privacyPolicy.withConsent")}</h3>
            <p>{t("client.privacyPolicy.withConsentDetail")}</p>
          </div>

          <h2>{t("client.privacyPolicy.security")}</h2>
          <p>{t("client.privacyPolicy.securityDetail")}</p>

          <h2>{t("client.privacyPolicy.choices")}</h2>
          <p>{t("client.privacyPolicy.choicesDetail")}</p>

          <h2>{t("client.privacyPolicy.childrenPrivacy")}</h2>
          <p>{t("client.privacyPolicy.childrenPrivacyDetail")}</p>

          <h2>{t("client.privacyPolicy.thirdPartyLinks")}</h2>
          <p>{t("client.privacyPolicy.thirdPartyLinksDetail")}</p>

          <h2>{t("client.privacyPolicy.policyChanges")}</h2>
          <p>{t("client.privacyPolicy.policyChangesDetail")}</p>

          <h2>{t("client.privacyPolicy.contact")}</h2>
          <p>{t("client.privacyPolicy.contactDetail")}</p>
        </div>
      </div>
    </ClientPage>
  );
}
