import { SERVER_API } from "../helpers/variable";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToast } from "../context/ToastContext";
import { store } from "../redux/store";
import {
  providerLoginSuccess,
  providerLogoutSuccess,
} from "../redux/slices/auth/provider";

export default function ProviderAuth() {
  const { showToast } = useToast();
  const navigate = useNavigate();

  async function providerSignup(provider) {
    await axios
      .post(`${SERVER_API}/providers/sign_up`, { provider: { ...provider } })
      .then((response) => {
        if (response.status == 200) {
          navigate("/signin");
          showToast({
            message: "Please check your email and confirm it!",
            flag: "success",
          });
        } else {
          showToast({
            message: response.data.message,
            flag: "error",
          });
        }
      })
      .catch((error) => {
        showToast({ message: error.response.data?.message, flag: "error" });
      });
  }

  async function providerSignin(provider) {
    await axios
      .post(`${SERVER_API}/providers/login`, { provider: { ...provider } })
      .then((response) => {
        if (
          response.data.active &&
          response.data.account_verified &&
          response.data.initial_profile_setup == "completed"
        ) {
          showToast({ message: "Succesfully SignedIn!", flag: "success" });
          store.dispatch(
            providerLoginSuccess({
              provider: response.data,
              token: response.headers.authorization,
            })
          );
          return navigate("/provider/profile");
        } else if (
          response.data.initial_profile_setup &&
          response.data.initial_profile_setup !== "completed"
        ) {
          showToast({ message: "Succesfully SignedIn!", flag: "success" });
          store.dispatch(
            providerLoginSuccess({
              provider: response.data,
              token: response.headers.authorization,
            })
          );
          navigate(`/provider/profileSetup/${response.data.setup_stage}`);
        } else {
          showToast({
            message: response.data.message,
            flag: "error",
          });
        }
      })
      .catch((error) => {
        showToast({ message: error.response.data.message, flag: "error" });
      });
  }

  return {
    providerSignin,
    providerSignup,
  };
}
