import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useToast } from "../context/ToastContext";
import { useLocation } from "react-router-dom";

export default function ClientPrivateRoute({ children }) {
  const { showToast } = useToast();
  const { client, isClientAuthenticated } = useSelector(
    (state) => state.clientAuth
  );
  const navigate = useNavigate();
  const access_token = Cookies.get("client-access-token");

  if (
    isClientAuthenticated &&
    access_token &&
    client.account_verified &&
    client.active &&
    client.initial_profile_setup === "completed"
  ) {
    return children;
  } else {
    if (client && !client.account_verified) {
      showToast({ message: "Please verified your email", flag: "error" });
    } else if (client && !client.active) {
      showToast({
        message: "Your account is not active, please contact to administrator",
        flag: "error",
      });
    } else if (client && client.initial_profile_setup !== "completed") {
      navigate(`/client/profileSetup/${client.setup_stage}`);
      return;
    }
    navigate("/signin");
    return;
  }
}
