import React from "react";
import { useTranslation } from "react-i18next";
import DaysAgo from "../../../../../components/general/DaysAgo";
import { Rating, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Reviews as ReviewsIcon } from "@mui/icons-material";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(25px)",
      color: "#193d66",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#ffffff" : "#ffffff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#e8e8e8" : "#e8e8e8",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function Review({ reviews, handleApproved, handleDelete }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="clientreview__container__client__title">
        <h2>{t("providers.clientReview.clientReviewHeader")}</h2>
      </div>
      <section className="clientreview__container__client__review">
        {reviews.length > 0 ? (
          reviews.map((review) => (
            <div className="clientreview__container__client__review__boxContainer">
              <div className="clientreview__container__client__review__boxContainer__rating">
                <div className="clientreview__container__client__review__boxContainer__rating__profile">
                  <div className="clientreview__container__client__review__boxContainer__rating__profile__image">
                    <img
                      src={
                        review.client.profile_image ||
                        require("../../../../../assets/NoPath - Copy (185).png")
                      }
                    />
                  </div>
                  <div className="clientreview__container__client__review__boxContainer__rating__profile__information">
                    <div className="clientreview__container__client__review__boxContainer__rating__profile__information__name">
                      <span>{review.client.first_name}</span>
                    </div>
                    <div className="clientreview__container__client__review__boxContainer__rating__profile__information__point">
                      <div className="clientreview__container__client__review__boxContainer__rating__profile__information__point__number">
                        <span>
                          {" "}
                          <Rating
                            name="read-only"
                            value={review.rating}
                            readOnly
                          />
                        </span>
                      </div>
                      <div className="clientreview__container__client__review__boxContainer__rating__profile__information__point__iconbox"></div>
                    </div>
                  </div>
                </div>
                <div className="clientreview__container__client__review__boxContainer__rating__container">
                  <div className="clientreview__container__client__review__boxContainer__rating__container__days">
                    <span>
                      <DaysAgo date={review.created_at} />
                    </span>
                  </div>
                  {/* <Tooltip
                    title={
                      review.provider_approved ? "Approved" : "Not Approved"
                    }
                  >
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={review.provider_approved}
                          onChange={(e) => handleApproved(e, review.id)}
                        />
                      }
                    />
                  </Tooltip>

                  <div className="clientreview__container__client__review__boxContainer__rating__container__icon">
                    <DeleteOutlineOutlinedIcon
                      onClick={() => handleDelete(review.id)}
                    />
                  </div> */}
                </div>
              </div>
              <div className="clientreview__container__client__review__boxContainer__paragraph">
                <p>{review.review}</p>
              </div>
            </div>
          ))
        ) : (
          <h1 className="create_title">
            <ReviewsIcon fontSize="large" /> {t("client.title.noReviewyet")}
          </h1>
        )}
        <div></div>
      </section>
    </React.Fragment>
  );
}
