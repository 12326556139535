import React, { useState } from "react";
import GreenInput from "../../../components/Form/GreenInput";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, IconButton } from "@mui/material";
import {
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  Email as EmailIcon,
  Person as PersonIcon,
  AccountCircle as AccountCircleIcon,
  Person2 as Person2Icon,
} from "@mui/icons-material";
const MINIMUM_AGE = 18;

// Function to get the minimum birthdate
const getMinimumBirthdate = () => {
  const today = new Date();
  today.setFullYear(today.getFullYear() - MINIMUM_AGE);
  return today;
};

const schema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  screen_name: yup.string().required(),
  email: yup.string().required(),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one capital letter"),
  dob: yup
    .date()
    .required("Birthdate is required")
    .max(
      getMinimumBirthdate(),
      `You must be at least ${MINIMUM_AGE} years old`
    ),
});

export default function SignupForm({ user, setUser, onSubmit }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState({
    password: false,
  });
  const [passwordNotMatch, setPasswordNotMatch] = useState(true);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: user,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };
  const handleClick = (field) => {
    setPasswordVisible((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };
  return (
    <section className="providerSignUp">
      <form
        className="providerSignUp__container"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="providerSignUp__container__nameContainer">
          <div className="providerSignUp__container__nameContainer__1st">
            <Controller
              render={({ field }) => (
                <GreenInput
                  label={t("client.signUp.firstName")}
                  setUser={setUser}
                  field={field}
                  icon={
                    <IconButton>
                      <PersonIcon />
                    </IconButton>
                  }
                />
              )}
              name="first_name"
              control={control}
            />
          </div>
          <div className="providerSignUp__container__nameContainer__last">
            <Controller
              render={({ field }) => (
                <GreenInput
                  label={t("client.signUp.lastName")}
                  setUser={setUser}
                  field={field}
                  icon={
                    <IconButton>
                      <Person2Icon />
                    </IconButton>
                  }
                />
              )}
              name="last_name"
              control={control}
            />
          </div>
        </div>
        <Controller
          render={({ field }) => (
            <GreenInput
              label={t("client.signUp.screenName")}
              setUser={setUser}
              field={field}
              icon={
                <IconButton>
                  <AccountCircleIcon />
                </IconButton>
              }
            />
          )}
          name="screen_name"
          control={control}
        />
        <Controller
          render={({ field }) => (
            <GreenInput
              label={t("client.signUp.email")}
              type={"email"}
              setUser={setUser}
              field={field}
              icon={
                <IconButton>
                  <EmailIcon />
                </IconButton>
              }
            />
          )}
          name="email"
          control={control}
        />
        <Controller
          render={({ field }) => (
            <GreenInput
              label={t("client.signUp.password")}
              type={passwordVisible.password ? "text" : "password"}
              icon={
                passwordVisible.password ? (
                  <IconButton onClick={() => handleClick("password")}>
                    <VisibilityOffOutlinedIcon className="passwordChange__container__passIcon" />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleClick("password")}>
                    <VisibilityOutlinedIcon className="passwordChange__container__passIcon" />
                  </IconButton>
                )
              }
              setUser={setUser}
              field={field}
            />
          )}
          name="password"
          control={control}
        />
        {errors.password && (
          <p className="passwordErrorMessage">{errors.password.message}</p>
        )}

        <Controller
          render={({ field }) => (
            <GreenInput
              label={t("client.signUp.birthday")}
              type="date"
              setUser={setUser}
              field={field}
            />
          )}
          name="dob"
          control={control}
        />
        {errors.dob && (
          <p className="passwordErrorMessage">{errors.dob.message}</p>
        )}
        <div className="providerSignUp__container__terms">
          <DoneOutlinedIcon className="providerSignUp__container__terms__icon" />
          <p className="providerSignUp__container__terms__text">
            {t("client.signUp.terms")}{" "}
            <span
              className="providerSignUp__container__condition"
              onClick={() => navigate("/term-&-conditions")}
            >
              {t("client.signUp.condition")}
            </span>
          </p>
        </div>
        <Button
          className="providerSignUp__container__signUpButton"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          {t("client.signUp.signUp")}{" "}
        </Button>
      </form>
      <p className="providerSignUp__signIn">
        {t("client.signIn.account")}
        {"  "}
        <b
          className="providerSignUp__container__condition"
          onClick={() => navigate("/signin")}
        >
          {t("client.signIn.signIn")}
        </b>
      </p>
    </section>
  );
}
