import React, { useState,useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
const ChatSidebar = () => {
  const navigate = useNavigate();
  const message = [
    {
      id: "1",
      image: require("../../../assets/NoPath - Copy (228).png"),
      name: "John Doe",
      message: "How long does it take to get to your house",
      day: "2 days ago",
    },
    {
      id: "2",
      image: require("../../../assets/NoPath - Copy (228).png"),
      name: "John Doe",
      message: "How long does it take to get to your house",
      day: "2 days ago",
    },
    {
      id: "3",
      image: require("../../../assets/NoPath - Copy (228).png"),
      name: "John Doe",
      message: "How long does it take to get to your house",
      day: "2 days ago",
    },
    {
      id: "4",
      image: require("../../../assets/NoPath - Copy (228).png"),
      name: "John Doe",
      message: "How long does it take to get to your house",
      day: "2 days ago",
    },
    {
      id: "5",
      image: require("../../../assets/NoPath - Copy (228).png"),
      name: "John Doe",
      message: "How long does it take to get to your house",
      day: "2 days ago",
    },
    {
      id: "6",
      image: require("../../../assets/NoPath - Copy (228).png"),
      name: "John Doe",
      message: "How long does it take to get to your house",
      day: "2 days ago",
    },
    {
      id: "7",
      image: require("../../../assets/NoPath - Copy (228).png"),
      name: "John Doe",
      message: "How long does it take to get to your house",
      day: "2 days ago",
    },
    {
      id: "8",
      image: require("../../../assets/NoPath - Copy (228).png"),
      name: "John Doe",
      message: "How long does it take to get to your house",
      day: "2 days ago",
    },
    {
      id: "9",
      image: require("../../../assets/NoPath - Copy (228).png"),
      name: "John Doe",
      message: "How long does it take to get to your house",
      day: "2 days ago",
    },
    {
      id: "10",
      image: require("../../../assets/NoPath - Copy (228).png"),
      name: "John Doe",
      message: "How long does it take to get to your house",
      day: "2 days ago",
    },
  ];
  useEffect(() => {
    if (window.innerWidth > 768) {
      navigate("/chat");
    } 
  }, []);
  const handleSubmit = (id) => {
    if (window.innerWidth <= 768) {
      navigate(`/chat/message/${id}`);
    }
  };

  return (
    <section className="chat__sidebar">
      <div className="chat__sidebar__searchContainer">
        <SearchIcon
          className="chat__sidebar__searchContainer__textfield__searchIcon"
          fontSize="small"
        />

        <div className="chat__sidebar__searchContainer__textfield">
          <input type="text" placeholder="Search" />
        </div>
      </div>
      <div className="chat__sidebar__title">
        <span>Message</span>
      </div>
      <div className="chat__sidebar__responsivetitlebar">
        <div className="chat__sidebar__responsivetitlebar__icon">
          <ArrowBackIosNewIcon fontSize="small" />
        </div>
        <span className="chat__sidebar__responsivetitlebar__title">
          <span>Chat</span>
        </span>
      </div>
      <div className="chat__sidebar__border"></div>
      <div className="chat__sidebar__scrollcontainer">
        {message.map((item) => (
          <div
            className="chat__sidebar__messageContainer"
            onClick={() => handleSubmit(item.id)}
          >
            <div className="chat__sidebar__messageContainer__image">
              <img src={item.image} />
            </div>
            <div className="chat__sidebar__messageContainer__subContainer">
              <div className="chat__sidebar__messageContainer__subContainer__name">
                <span>{item.name}</span>
              </div>
              <div className="chat__sidebar__messageContainer__subContainer__message">
                <div className="chat__sidebar__messageContainer__subContainer__message__paragraph">
                  <p>{item.message}</p>
                </div>
              </div>
            </div>
            <div className="chat__sidebar__messageContainer__subContainer__message__days">
              <span>{item.day}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
export default ChatSidebar;
