import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function ClientProfileSetupRoute({ children }) {
  const { client, isClientAuthenticated } = useSelector(
    (state) => state.clientAuth
  );
  const access_token = Cookies.get("client-access-token");
  const navigate = useNavigate();

  if (isClientAuthenticated && access_token && client && !client.active) {
    return children;
  } else if (isClientAuthenticated && access_token && client && client.active) {
    navigate("/");
    return;
  } else {
    navigate("/signin");
  }
}
