import React, { useState } from "react";
import GreenInput from "../../../../../components/Form/GreenInput";
import BlueButton from "../../../../../components/Form/BlueButton";
import GreenSelect from "../../../../../components/Form/GreenSelect";
import ProfileApis from "../../../../../apis/client/ProfileApis";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import GreenPhoneInput from "../../../../../components/Form/GreenPhoneInput";
import { Box, Modal } from "@mui/material";
import {
  Edit as EditIcon,
  ArrowBackIos as ArrowBackIosIcon,
  PhotoCameraOutlined as PhotoCameraOutlinedIcon,
} from "@mui/icons-material";

const schema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  screen_name: yup.string().required("Screen name is required"),
  dob: yup.string().required("Birthday is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
});

const optionValues = [
  { label: "Select Country", value: "" },
  {
    label: "USA",
    value: "USA",
  },
  {
    label: "MX",
    value: "MX",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "none",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  width: 900,
  // overflowY: "auto",
};
export default function EditProfile({ profile, setProfile }) {
  const { t } = useTranslation();
  const { updatePersonalInformation, isProfileLoading } = ProfileApis();
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [open, setOpen] = useState(false);
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: profile.first_name,
      last_name: profile.last_name,
      screen_name: profile.screen_name,
      phone: profile.phone,
      dob: profile.dob,
      email: profile.email,
      street: profile.street,
      city: profile.city,
      state: profile.state,
      zip: profile.zip,
      country: profile.country,
    },
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleImages = (e) => {
    const { name, files } = e.target;
    const reader = new FileReader();
    if (name === "profile_image") {
      reader.onload = (e) => {
        setProfileImage(e.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
    handleClientChange(e);
  };

  const handleClientChange = (e) => {
    const { value, name, files } = e.target;
    debugger;
    if (name == "profile_image") {
      setProfileImageFile(files[0]);
    } else {
      setProfile({
        ...profile,
        [name]: value,
      });
    }
  };

  const onSubmit = async (data) => {
    debugger;
    const params = {
      ...data,
    };
    if (profileImage) {
      params["profile_image"] = profileImageFile;
    }
    const response = await updatePersonalInformation(params);
    if (response.errorMessage) {
      // setErrorMessage(response.errorMessage);
    } else {
      setProfile(response.data);
      handleClose();
    }
  };

  return (
    <main>
      <div onClick={handleOpen}>
        <EditIcon className="provider__profile__container__clientPorfile__profile__image__editIcon__icon" />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(15px)",
          WebkitBackdropFilter: "blur(15px)",
        }}
      >
        <Box sx={style} className="editprofilesection">
          <section className="profileedit">
            <header className="modal__container" style={{ width: "100%" }}>
              <div className="modal__container__icon" onClick={handleClose}>
                <ArrowBackIosIcon />
              </div>

              <h3 className="editService__modal__title">Set Up Your Profile</h3>
            </header>
            <form
              className="profileedit__container"
              onSubmit={handleSubmit(onSubmit)}
            >
              <section className="profileedit__container__body">
                <aside className="profileSetup__container__body__left">
                  <header className="profileSetup__container__body__left__profilePicture">
                    <section className="profileSetup__container__body__left__profilePicture__profilePictureBox">
                      <figure>
                        <label for="image">
                          <PhotoCameraOutlinedIcon className="profileSetup__container__body__left__profilePicture__profilePictureBox__icon" />
                          <img
                            src={
                              profileImage
                                ? profileImage
                                : profile.profile_image
                                ? profile.profile_image
                                : require("../../../../../assets/Profile_Image.jpg")
                            }
                            alt=""
                          />
                        </label>
                      </figure>
                      <input
                        type="file"
                        id="image"
                        name="profile_image"
                        onChange={(e) => handleImages(e)}
                      />
                    </section>
                    <h3>Add Profile Picture</h3>
                  </header>
                  <section className="profileSetup__container__body__left__inputBox">
                    <div>
                      <GreenInput
                        label="First Name"
                        handleChange={handleClientChange}
                        error={errors.first_name?.message}
                        register={register("first_name")}
                      />
                    </div>
                    <div>
                      <GreenInput
                        label="Last Name"
                        register={register("last_name")}
                        handleChange={handleClientChange}
                        error={errors.last_name?.message}
                      />
                    </div>
                  </section>

                  <GreenInput
                    label="Email"
                    type="email"
                    handleChange={handleClientChange}
                    error={errors.email?.message}
                    register={register("email")}
                    readOnly
                  />
                  <GreenInput
                    label="Screen Name"
                    handleChange={handleClientChange}
                    error={errors.screen_name?.message}
                    register={register("screen_name")}
                    readOnly
                  />
                </aside>
                <section className="profileedit__container__divider"></section>
                <aside className="profileSetup__container__body__right">
                  <GreenInput
                    label="Birthday"
                    type="date"
                    handleChange={handleClientChange}
                    error={errors.dob?.message}
                    register={register("dob")}
                  />
                  <GreenPhoneInput
                    label={t("client.personalInformation.phoneNumber")}
                    name="phone"
                    handleChange={handleClientChange}
                    value={profile.phone}
                    selectedCountry={profile.country}
                  />

                  <GreenInput
                    name="street"
                    handleChange={handleClientChange}
                    label={t("providers.s.myProfile.streetaddress")}
                    placeholder={"Type here..."}
                    type={"text"}
                    register={register("street")}
                    error={errors.street?.message}
                  />
                  <section className="profileSetup__container__body__left__inputBox">
                    <div>
                      <GreenInput
                        name="city"
                        handleChange={handleClientChange}
                        label={t("providers.myProfile.city")}
                        placeholder={"Type here..."}
                        type={"text"}
                        register={register("city")}
                        error={errors.city?.message}
                      />
                    </div>
                    <div>
                      <GreenInput
                        name="state"
                        handleChange={handleClientChange}
                        label={t("providers.myProfile.state")}
                        placeholder={"Type here..."}
                        type={"text"}
                        register={register("state")}
                        error={errors.state?.message}
                      />
                    </div>
                  </section>
                  <section className="profileSetup__container__body__left__inputBox">
                    <div>
                      <GreenInput
                        name="zip"
                        handleChange={handleClientChange}
                        label={t("providers.myProfile.zip")}
                        placeholder={"Type here..."}
                        type={"number"}
                        register={register("zip")}
                        error={errors.zip?.message}
                      />
                    </div>
                    <div>
                      <GreenSelect
                        name="country"
                        label={t("providers.myProfile.country")}
                        options={optionValues}
                        handleChange={handleClientChange}
                        register={register("country")}
                        error={errors.country?.message}
                      />
                    </div>
                  </section>
                </aside>
              </section>
              <section className="profileSetup__container__nextButton">
                <BlueButton
                  title={"Save"}
                  type="submit"
                  loading={isProfileLoading}
                />
              </section>
            </form>
          </section>
        </Box>
      </Modal>
    </main>
  );
}
