import React, { useEffect } from "react";
import ChatSidebar from "./ChatSidebar/index";
import Message from "./Message/index";
import { useNavigate } from "react-router-dom";

export default function BasicChat() {
  const navigate = useNavigate();
  useEffect(() => {
    if (window.innerWidth > 768) {
      navigate("/chat");
    }
  }, []);
  return (
    <section className="chat">
      <div className="chatSidebar">
        <ChatSidebar />
      </div>
      <div className="messages">
        <Message />
      </div>
    </section>
  );
}
