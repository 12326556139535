import React, { useState } from "react";
import ServiceHourTab from "./SericeHourTab";
import AddServiceHourModal from "./SericeHourTab/components/AddServiceHourModal";
import Button from "@mui/material/Button";

export default function ServiceHour() {
  const [refetch, setRefetch] = useState(false);

  return (
    <main className="serviceHour">
      <section className="srviceHour__container">
        <div className="serviceHour__buttonBox">
          <AddServiceHourModal refetch={refetch} setRefetch={setRefetch} />
        </div>
        <ServiceHourTab refetch={refetch} setRefetch={setRefetch} />
        <div className="serviceHour__buttonbox">
          <AddServiceHourModal refetch={refetch} setRefetch={setRefetch} />
        </div>
      </section>
    </main>
  );
}
