import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  client: null,
  isClientAuthenticated: false,
};

export const clientSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clientData(state, action) {
      const { client } = action.payload;
      state.client = client;
    },
    clientLoginSuccess(state, action) {
      const { client, token } = action.payload;
      state.client = client;
      state.isClientAuthenticated = true;
      Cookies.set("client-access-token", token, { expires: 1 });
    },
    clientLogoutSuccess(state) {
      state.client = null;
      state.isClientAuthenticated = false;
      Cookies.remove("client-access-token");
    },
  },
});

export const { clientLoginSuccess, clientLogoutSuccess, clientData } =
  clientSlice.actions;
export default clientSlice.reducer;
