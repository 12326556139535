import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddRateModal from "../components/AddRateModal";
import { useTranslation } from "react-i18next";

export default function RateTable({ rates, fetchRates, destroyRate }) {
  const { t } = useTranslation();

  return (
    <>
      {rates.length > 0 ? (
        <table className="rate__tablecontainer__table__section">
          {rates.map((rate) => (
            <section className="rate__tablecontainer__table__section__container">
              <div className="rate__tablecontainer__table__section__container__dollartextfield">
                <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput">
                  <label>{t("providers.myRate.price")}</label>
                  <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox">
                    <input value={`$ ${rate.price}`} />
                  </div>
                </div>
              </div>
              <div className="rate__tablecontainer__table__section__container__dollartextfield">
                <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput">
                  <label>{t("providers.myRate.time")}</label>
                  <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox">
                    <icon className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox__icon"></icon>
                    <input value={rate.time_duration} />
                  </div>
                </div>
              </div>

              <div className="rate__tablecontainer__table__section__container__Regularbox">
                <div className="greenSelect">
                  <label>{t("providers.myRate.description")}</label>
                  <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox">
                    <input value={rate.description} />
                  </div>
                </div>
              </div>
              <div className="rate__tablecontainer__table__section__container__Regularbox">
                <div className="greenSelect">
                  <label>{t("providers.myRate.rateType")}</label>
                  <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox">
                    <input value={rate.rate_type} />
                  </div>
                </div>
              </div>
              <div className="rate__tablecontainer__table__section__container__Regularbox">
                <div className="greenSelect">
                  <label>{t("providers.myRate.service")}</label>
                  <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox">
                    <input value={rate.service.name} />
                  </div>
                </div>
              </div>
              <div className="rate__tablecontainer__table__section__container__buttonbox">
                <div>
                  <AddRateModal
                    rateParams={{
                      id: rate.id,
                      description: rate.description,
                      service_id: rate.service_id,
                      price: rate.price,
                      time_duration: rate.time_duration,
                    }}
                    fetchRates={fetchRates}
                    title="Edit"
                  />
                </div>
                <div>
                  <DeleteIcon
                    sx={{ color: "red" }}
                    onClick={() => destroyRate(rate.id)}
                  />
                </div>
              </div>
            </section>
          ))}
        </table>
      ) : (
        <h1 className="create_title"> {t("client.title.CreateRates")}</h1>
      )}

      {/* <div className="rate__tablecontainer__table__section__buttonbox">
        <Button className="rate__tablecontainer__table__section__buttonbox__button">
          Update
        </Button>
      </div> */}
    </>
  );
}
