import React, { useEffect, useState } from "react";
import Card from "./Card";
import LandingPageCarousel from "./LandingPageCarousel";
import { Outlet } from "react-router-dom";
import { Navigation, Pagination, Autoplay, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { useTranslation } from "react-i18next";
import SkeletonCard from "../../../../../components/general/Skeleton";
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  ArrowBackIosNew as ArrowBackIosNewIcon,
} from "@mui/icons-material";

const HomeTab = ({ ads, isLoading }) => {
  const { t } = useTranslation();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const breakpoints = {
    400: {
      slidesPerView: 1,
    },
    550: {
      slidesPerView: 2,
    },
    800: {
      slidesPerView: 3,
    },
    1000: {
      slidesPerView: 4,
    },
    1800: {
      slidesPerView: 5,
    },
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getSlidesPerView = () => {
    return Object.keys(breakpoints)
      .sort((a, b) => b - a)
      .find((breakpoint) => screenWidth >= breakpoint);
  };

  const slidesPerView = breakpoints[getSlidesPerView()]?.slidesPerView || 1;

  return (
    <main className="landingPage">
      {/* <LandingPageCarousel /> */}
      <section className="landingPage__container">
        {/* <div className="landingPage__container__featured"> */}
        {/* <div className="landingPage__container__featured__header"> */}
        <h1 className="landingPage__container__featured__header">
          {t("client.landingPage.topRated")}
        </h1>
        <section className="landingPage__container__cardsContainer1">
          <div className="landingPage__container__cardsContainer1__arrowIcons">
            <ArrowBackIosNewIcon className="landingPage__container__cardsContainer1__arrowIcons__backward" />
            <ArrowForwardIosIcon className="landingPage__container__cardsContainer1__arrowIcons__forward" />
          </div>
          {isLoading ? (
            <SkeletonCard />
          ) : (
            <Swiper
              modules={[Navigation, Pagination, A11y, Autoplay]}
              spaceBetween={130}
              slidesPerView={slidesPerView}
              className="landingPage__container__cardsContainer1__cards"
              navigation={{
                prevEl:
                  ".landingPage__container__cardsContainer1__arrowIcons__backward",
                nextEl:
                  ".landingPage__container__cardsContainer1__arrowIcons__forward",
              }}
              autoplay={{
                delay: 2000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
              }}
              // loop={true}
            >
              {ads?.top_rated.map((top_rated) => (
                <SwiperSlide>
                  <Card ad={top_rated} isLoading={isLoading} />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </section>
        {/* <div className="landingPage__container__featured">
          <div className="landingPage__container__featured__header" > */}
        <h1 className="landingPage__container__featured__header">
          {t("client.landingPage.proproviders")}
        </h1>
        {/* </div>
        </div> */}
        <section className="landingPage__container__cardsContainer">
          <section className="landingPage__container__cardsContainer1__arrowIcons">
            <ArrowBackIosNewIcon className="landingPage__container__cardsContainer1__arrowIcons__backward" />
            <ArrowForwardIosIcon className="landingPage__container__cardsContainer1__arrowIcons__forward" />
          </section>
          <Swiper
            modules={[Navigation, Pagination, A11y, Autoplay]}
            spaceBetween={300}
            slidesPerView={slidesPerView}
            className="landingPage__container__cardsContainer1__cards"
            navigation={{
              prevEl:
                ".landingPage__container__cardsContainer1__arrowIcons__backward",
              nextEl:
                ".landingPage__container__cardsContainer1__arrowIcons__forward",
            }}
            autoplay={{
              delay: 2000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            // loop={true}
          >
            {ads?.pro_providers.map((top_rated) => (
              <SwiperSlide>
                <Card ad={top_rated} favourite={false} />
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      </section>
      <Outlet />
    </main>
  );
};

export default HomeTab;
