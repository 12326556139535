import React from 'react';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

const CardOptions = ({ description }) => {
  return (
    <section>
      <aside className='selectLevel__body__cards__card__body__textBox__text'>
        <DoneOutlinedIcon className='selectLevel__body__cards__card__body__textBox__text__icon' />
        <p>{description}</p>
      </aside>
    </section>
  );
}

export default CardOptions;
