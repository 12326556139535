import React, { useState } from "react";
import ClientAuth from "../../../apis/ClientAuth";
import SignupForm from "./SignupForm";



export default function ClientSignup() {
  const { clientSignup } = ClientAuth();

  const [client, setClient] = useState({
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    dob: null,
    screen_name: null,
  });

  const onSubmit = async (data) => {
    await clientSignup(data);
  };

  return (
    <SignupForm user={client} setUser={setClient} onSubmit={onSubmit} />
  );
}
