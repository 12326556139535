import React, { useMemo } from "react";

export default function PrepareAdData(adsData) {
  const serviceOptionValues = useMemo(
    () =>
      adsData.services.map((service) => ({
        label: service.name,
        value: service.id,
      })),

    [adsData]
  );
  const massageOptionValues = useMemo(
    () =>
      adsData.massage_types.map((massage) => ({
        label: massage.name,
        value: massage.id,
      })),
    [adsData]
  );

  const rateOptionValues = useMemo(
    () =>
      adsData.rates.map((rate) => ({
        label: rate.name,
        value: rate.id,
      })),
    [adsData]
  );
  const serviceHourOptionValues = useMemo(
    () =>
      adsData.service_hours.map((service_hour) => ({
        label: service_hour.name,
        value: service_hour.id,
      })),
    [adsData]
  );

  return {
    serviceOptionValues,
    massageOptionValues,
    rateOptionValues,
    serviceHourOptionValues,
  };
}
