import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import GeneralApis from "../../../../apis/GeneralApis";
import Loading from "../../../../components/general/Loading";
import Textarea from "@mui/joy/Textarea";
import ClientPage from "../../../../components/PageWrapper/ClientPage";

export default function ContactUs() {
  const { t } = useTranslation();
  const { isApiLoading, sendSupportEmail } = GeneralApis();

  const [support, setSupport] = useState({
    name: "",
    email: "",
    subject: "",
    body: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSupport((prevSupport) => ({
      ...prevSupport,
      [name]: value,
    }));
  };

  const handleSendButtonClick = async () => {
    await sendSupportEmail(support);
  };

  if (isApiLoading) return <Loading />;

  return (
    <ClientPage>
      <div className="contactUs">
        <h1>Contact US</h1>
        <section className="support__form__searchBox">
          <div className="support__form__searchBox__search">
            <label className="support__form__searchBox__search__searchLabel">
              {t("client.contactUs.name")}
            </label>
            <input
              name="name"
              type="text"
              placeholder={t("providers.support.typeHere")}
              className="support__form__searchBox__search__searchInput"
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </section>
        <section className="support__form__searchBox">
          <div className="support__form__searchBox__search">
            <label className="support__form__searchBox__search__searchLabel">
              {t("client.contactUs.email")}
            </label>
            <input
              name="email"
              type="email"
              placeholder={t("providers.support.typeHere")}
              className="support__form__searchBox__search__searchInput"
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </section>
        <section className="support__form__searchBox">
          <div className="support__form__searchBox__search">
            <label className="support__form__searchBox__search__searchLabel">
              {t("client.contactUs.subject")}
            </label>
            <input
              name="subject"
              type="text"
              placeholder={t("providers.support.typeHere")}
              className="support__form__searchBox__search__searchInput"
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </section>
        <section style={{ marginTop: "20px" }}>
          <div className="support__form__searchBox__search">
            <label className="support__form__searchBox__search__searchLabel">
              {t("client.contactUs.Content")}
            </label>
            <Textarea
              size="lg"
              name="body"
              placeholder="Write description here...."
              minRows={5}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </section>

        <div className="support__form__button">
          <Button
            variant="contained"
            size="small"
            className="support__form__button__sendButton"
            onClick={handleSendButtonClick}
          >
            {t("client.contactUs.send")}
          </Button>
        </div>
      </div>
    </ClientPage>
  );
}
