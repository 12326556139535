import React from "react";
import { MultiSelect } from "react-multi-select-component";

const GreenMultiSelect = ({ name, value, label, options, handleChange }) => {
  return (
    <div>
      <label className="label">{label}</label>
      <MultiSelect
        name={name}
        options={options}
        value={value}
        onChange={(e) => handleChange(e, name)}
      />
    </div>
  );
};

export default GreenMultiSelect;
