import React, { useState } from "react";
import { Box, Drawer, Button } from "@mui/material";
import { Sort as SortIcon } from "@mui/icons-material";
import Filter from "./Filter";

export default function FilterDrawer({ setAds, search }) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [filterValues, setFilterValues] = useState({
    from_price: 5,
    to_price: 20,
    work_surface_ids: [],
    work_location_ids: [],
    sexual_orientation_ids: [],
    ethnicity_ids: [],
    language_ids: [],
    drug_frequency_ids: [],
    smoking_frequency_ids: [],
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : "100%",
      }}
      role="presentation"
    >
      <Filter
        setAds={setAds}
        search={search}
        setFilterValues={setFilterValues}
        filterValues={filterValues}
        toggleDrawer={toggleDrawer}
        anchor={anchor}
      />
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            {" "}
            <SortIcon className="massageTab__filters__filter__box__icon" />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
