import React, { useState } from "react";
import { SERVER_API } from "../../helpers/variable";
import Cookies from "js-cookie";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { onSuccess, onError } from "./request";
import { useSelector } from "react-redux";

export default function FavouriteApis() {
  const { showToast } = useToast();
  const [isFavouriteLoading, setIsFavouriteLoading] = useState(false);
  const { language } = useSelector((state) => state.i18n);

  async function getFavourites() {
    setIsFavouriteLoading(true);
    return await axios
      .get(`${SERVER_API}/clients/favourites?locale=${language.id}`, {
        headers: {
          Authorization: Cookies.get("client-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        setIsFavouriteLoading(false);
        onSuccess(response, showToast, "Data fetched!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        setIsFavouriteLoading(false);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function deleteFavourite(ad_id) {
    setIsFavouriteLoading(true);
    return await axios
      .delete(
        `${SERVER_API}/clients/favourites/${ad_id}?locale=${language.id}`,
        {
          headers: {
            Authorization: Cookies.get("client-access-token"),
          },
        }
      )
      .then((response) => {
        setIsFavouriteLoading(false);
        onSuccess(response, showToast, "Deleted Successfully!");
        return {
          data: true,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsFavouriteLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function addFavourite(favourite) {
    setIsFavouriteLoading(true);
    return await axios
      .post(
        `${SERVER_API}/clients/favourites?locale=${language.id}`,
        favourite,
        {
          headers: {
            Authorization: Cookies.get("client-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        setIsFavouriteLoading(false);
        onSuccess(response, showToast, "Added Favourite!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsFavouriteLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  return {
    addFavourite,
    getFavourites,
    deleteFavourite,
    isFavouriteLoading,
  };
}
