import React from "react";
import { PatternFormat } from "react-number-format";

export const PHONE_FORMATS = [
  {
    country: "USA",
    phone_code: "+1",
    phone_format: "+1 (###) ###-####",
    use_postal_code: true,
  },
  {
    country: "MX",
    phone_code: "+52",
    phone_format: "+52 ## ## ### ###",
    use_postal_code: true,
  },
];
const GreenPhoneInput = ({
  name,
  label,
  type,
  icon,
  placeholder,
  value,
  required,
  handleChange,
  selectedCountry,
  register,
  error,
}) => {
  const phoneFormat = PHONE_FORMATS.find(
    (format) => format.country == selectedCountry
  );
  return (
    <div className="greenInput">
      <label>{label}</label>
      <div className="greenInput__inputBox">
        <icon className="greenInput__inputBox__icon">{icon}</icon>
        <PatternFormat
          format={phoneFormat.phone_format}
          allowEmptyFormatting
          mask="_"
          onChange={handleChange}
          name="phone"
          value={value}
          {...register}
        />
      </div>
    </div>
  );
};

export default GreenPhoneInput;
