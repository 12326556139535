import React, { useEffect, useState } from "react";
import VideoApis from "../../apis/provider/videoApis";
import Loading from "../general/Loading";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { Button, FormControlLabel, Checkbox } from "@mui/material";

export default function AdVideos({
  handleTabsChange,
  adData,
  setAdData,
  onSubmit,
}) {
  const { t } = useTranslation();

  const [videos, setVideos] = useState([]);
  const { isVideoLoading, getVideos } = VideoApis();
  const [errorMessage, setErrorMessage] = useState(null);
  const [videoId, setVideoId] = useState([]);

  const fetchVideos = async () => {
    const response = await getVideos();
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      setVideos(response.data);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  const handleVideoId = (id) => {
    const isSelected = adData.video_ids.includes(id);
    const videos = isSelected
      ? adData.video_ids.filter((item) => item !== id)
      : [...adData.video_ids, id];
    setAdData((prevState) => ({ ...prevState, video_ids: videos }));
  };

  if (isVideoLoading) return <Loading />;
  if (errorMessage) return null;
  return (
    <div className="newAdModal__body__pictures">
      <header>
        <h3>{t("providers.myAd.SelectVideo")}</h3>
      </header>
      <section
        className="myvideo__container__section"
        style={{ gap: "1rem", flexWrap: "wrap" }}
      >
        {videos.map((item, index) => (
          <div
            className="myvideo__container__section__container__box"
            key={index}
          >
            <div className="myvideo__container__section__container__box__image">
              <div style={{ height: "120px" }}>
                {item.file_url ? (
                  <ReactPlayer
                    url={item.file_url}
                    controls={true}
                    volume={1}
                    width={"100%"}
                    height={"100%"}
                    onClick={() => handleVideoId(item.id)}
                  />
                ) : (
                  <ReactPlayer
                    url={item.url}
                    controls={true}
                    volume={1}
                    width={"100%"}
                    height={"100%"}
                    onClick={() => handleVideoId(item.id)}
                  />
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: -60,
                  left: 120,
                  padding: ".8rem",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ m: 1 }}
                      onChange={() => handleVideoId(item.id)}
                      checked={adData.video_ids.includes(item.id)}
                    />
                  }
                />
              </div>
            </div>
            <div className="myvideo__container__section__container__box__paragraph">
              <span>{item.description}</span>
            </div>
            <div className="myvideo__container__section__container__box__viewscontainer">
              <div className="myvideo__container__section__container__box__viewscontainer__number">
                <span>5.7M Views</span>
              </div>
              <div className="myvideo__container__section__container__box__viewscontainer__time">
                <span>18h ago</span>
              </div>
            </div>
          </div>
        ))}
      </section>
      <div className="adVideoForm">
        <div className="adVideoForm__buttons">
          <Button
            variant="contained"
            className="newAdModalButton"
            onClick={(e) => handleTabsChange(e, 1)}
          >
            {t("providers.myAd.back")}
          </Button>
          <Button
            variant="contained"
            className="newAdModalButton"
            onClick={onSubmit}
          >
            {t("providers.myAd.submit")}
          </Button>
        </div>
      </div>
    </div>
  );
}
