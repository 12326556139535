import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useToast } from "../context/ToastContext";
import { useLocation } from "react-router-dom";
import ProfileApis from "../apis/provider/ProfileApis";
import Loading from "../components/general/Loading";

export default function ProviderPrivateRoute({ children }) {
  const { isProfileLoading, currentProfile } = ProfileApis();

  const { showToast } = useToast();
  const { provider, isproviderAuthenticated } = useSelector(
    (state) => state.providerAuth
  );
  const navigate = useNavigate();
  const access_token = Cookies.get("provider-access-token");
  const location = useLocation();
  const { hash, pathname, search } = location;
  const path = pathname.split("/")[-1];
  const accountState =
    provider && provider.account_trial
      ? provider.account_trial
      : provider?.subscribed;

  const getCurrentProfile = async () => {
    await currentProfile();
  };
  useEffect(() => {
    getCurrentProfile();
  }, []);

  if (isProfileLoading) return <Loading />;

  if (
    isproviderAuthenticated &&
    access_token &&
    provider.account_verified &&
    provider.active &&
    provider.initial_profile_setup == "completed" &&
    accountState
  ) {
    return children;
  } else {
    if (
      provider &&
      !provider.active &&
      provider.initial_profile_setup != "completed"
    ) {
      navigate(`/provider/profileSetup/${provider.setup_stage}`);
      return;
    }
    navigate("/signin");
    return;
  }
}
