import React from "react";
import { useTranslation } from "react-i18next";
import {
  Email as EmailIcon,
  Call as CallIcon,
  FmdGood as FmdGoodIcon,
} from "@mui/icons-material";
import EditProfile from "../components/EditProfile";

export default function ProfileInformation({ profile, setProfile }) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <section className="clientProfile__container__image">
        <figure className="clientProfile__container__image__box">
          <img
            src={
              profile.profile_image ||
              require("../../../../../assets/body-werx-1.jpeg")
            }
            alt="body-wrex-profile-image"
          />
          <main className="clientProfile__container__image__editIcon">
            <EditProfile
              // setErrorMessage={setErrorMessage}
              profile={profile}
              setProfile={setProfile}
            />
          </main>
        </figure>
      </section>
      <h4 className="clientProfile__container__title">{profile?.full_name}</h4>

      <summary className="clientProfile__container__about">
        <section className="clientProfile__container__about__email__container">
          <EmailIcon className="clientProfile__container__about__email__container__icon" />
          <h4>{profile.email}</h4>
        </section>
        <section className="clientProfile__container__about__contact__container">
          <CallIcon className="about__contact__container__callIcon" />
          <figure className="clientProfile__container__about__contact__container__country__image">
            <img src={require("../../../../../assets/NoPath - Copy (2).png")} />
          </figure>
          <h4>{profile.phone}</h4>
        </section>
        <section className="clientProfile__container__about__locationInformation">
          <FmdGoodIcon className="about__locationInformation__cityIcon" />
          <h4>{profile.address}</h4>
        </section>
      </summary>
    </React.Fragment>
  );
}
