import React from "react";
import { useTranslation } from "react-i18next";
import {
  StarRate as StarRateIcon,
  FavoriteBorder as FavoriteBorderIcon,
  LocationOn as LocationOnIcon,
  ThumbUpOutlined as ThumbUpOutlinedIcon,
  WorkspacePremiumOutlined as WorkspacePremiumOutlinedIcon,
} from "@mui/icons-material";
import Card from "../../../Home/components/Card";
const Service = ({ ads }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {ads.length > 0 ? (
        <article className="service">
          <section className="service__card">
            {ads.map((ad) => (
              // <aside className="landingPage__container__cardsContainer__cards__card">
              //   <section className="landingPage__container__cardsContainer__cards__card__imageBox">
              //     <img
              //       src={
              //         ad.profile_image ||
              //         require("../../../../../../assets/Profile_Image.jpg")
              //       }
              //       alt=""
              //       className="landingPage__container__cardsContainer__cards__card__imageBox__image"
              //     />
              //     <summary className="landingPage__container__cardsContainer__cards__card__imageBox__details">
              //       <section className="landingPage__container__cardsContainer__cards__card__imageBox__details__name">
              //         <h5>{ad.provider.screen_name}</h5>
              //         <FavoriteBorderIcon className="landingPage__container__cardsContainer__cards__card__imageBox__details__name__favIcon" />
              //       </section>
              //       <section className="landingPage__container__cardsContainer__cards__card__imageBox__details__rating">
              //         <h5>4.55</h5>
              //         <StarRateIcon className="landingPage__container__cardsContainer__cards__card__imageBox__details__rating__icon" />
              //       </section>
              //       <h4 className="landingPage__container__cardsContainer__cards__card__imageBox__details__bio">
              //         510 | 150 lbs | 29 {t("client.landingPage.year")}
              //       </h4>
              //     </summary>
              //   </section>
              //   <section className="landingPage__container__cardsContainer__cards__card__services">
              //     <h5>{t("client.landingPage.service")}</h5>
              //     <aside className="landingPage__container__cardsContainer__cards__card__services__offers">
              //       {ad?.massage_types.map((massage) => (
              //         <h4 className="landingPage__container__cardsContainer__cards__card__services__offers__offer">
              //           {massage?.name}
              //         </h4>
              //       ))}
              //     </aside>
              //   </section>
              //   <section className="landingPage__container__cardsContainer__cards__card__location">
              //     <LocationOnIcon className="landingPage__container__cardsContainer__cards__card__location__icon" />
              //     <h5>{ad.provider.street}</h5>
              //   </section>
              //   <footer className="landingPage__container__cardsContainer__cards__card__descBox">
              //     <aside className="landingPage__container__cardsContainer__cards__card__descBox__desc">
              //       <section className="landingPage__container__cardsContainer__cards__card__descBox__desc__review">
              //         <ThumbUpOutlinedIcon className="landingPage__container__cardsContainer__cards__card__descBox__desc__review__icon" />
              //         <h5>81 {t("client.landingPage.review")}</h5>
              //       </section>
              //       <section className="landingPage__container__cardsContainer__cards__card__descBox__desc__services">
              //         <WorkspacePremiumOutlinedIcon className="landingPage__container__cardsContainer__cards__card__descBox__desc__services__icon" />
              //         <h5>87 {t("client.landingPage.serviceCompleted")}</h5>
              //       </section>
              //     </aside>
              //     <section className="landingPage__container__cardsContainer__cards__card__descBox__price">
              //       <p>{t("client.landingPage.starting")}</p>
              //       <h5>${ad.starting_price}</h5>
              //     </section>
              //   </footer>
              // </aside>
              <Card ad={ad} />
            ))}
          </section>
        </article>
      ) : (
        <h3 className="noVideo__title"> {t("client.title.noServiceyet")}</h3>
      )}
    </React.Fragment>
  );
};
export default Service;
