import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import { Editor } from "@tinymce/tinymce-react";
import { useTranslation } from "react-i18next";
import GeneralApis from "../../../../../apis/GeneralApis";
import { useSelector } from "react-redux";
import Loading from "../../../../../components/general/Loading";
import Textarea from "@mui/joy/Textarea";

const SupportForm = () => {
  const { t } = useTranslation();
  const { provider } = useSelector((state) => state.providerAuth);
  const { isApiLoading, sendSupportEmail } = GeneralApis();

  const editorRef = useRef(null);
  const [support, setSupport] = useState({
    name: provider.screen_name,
    email: provider.email,
    subject: "",
    body: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSupport((prevSupport) => ({
      ...prevSupport,
      [name]: value,
    }));
  };

  const handleEditorChange = (content) => {
    setSupport((prevSupport) => ({
      ...prevSupport,
      body: content.value,
    }));
  };

  const handleSendButtonClick = async () => {
    await sendSupportEmail(support);
  };

  if (isApiLoading) return <Loading />;

  return (
    <>
      {/* <section className="support__form__searchBox"> */}
      {/* <div className="support__form__searchBox__search">
          <label className="support__form__searchBox__search__searchLabel">
            {t("providers.support.to")}
          </label>
          <input
            type="text"
            placeholder={t("providers.support.typeHere")}
            className="support__form__searchBox__search__searchInput"
            onChange={(e) => handleInputChange(e)}
            value={support.to} gfddxc≈hbmvvb 
            name="to"
          />
        </div> */}
      {/* </section> */}
      <section className="support__form__searchBox">
        <div className="support__form__searchBox__search">
          <label className="support__form__searchBox__search__searchLabel">
            {t("providers.support.subject")}
          </label>
          <input
            name="subject"
            type="text"
            placeholder={t("providers.support.typeHere")}
            className="support__form__searchBox__search__searchInput"
            value={support.subject}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
      </section>
      <section style={{ marginTop: "20px" }}>
        <Textarea
          size="lg"
          name="Size"
          placeholder="Large"
          minRows={5}
          onChange={(e) => handleEditorChange(e.target)}
        />
      </section>

      <div className="support__form__button">
        <Button
          variant="contained"
          size="small"
          className="support__form__button__sendButton"
          onClick={handleSendButtonClick}
        >
          {t("providers.support.next")}
        </Button>
      </div>
    </>
  );
};

export default SupportForm;
