import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AdsApis from "../../../../../apis/provider/AdsApis";
import Loading from "../../../../../components/general/Loading";
import MassageAndServicesForm from "../../../../../components/ProviderAd/MassageAndServicesForm";
import AdPhotos from "../../../../../components/ProviderAd/AdPhotos";
import AdVideos from "../../../../../components/ProviderAd/AdVideos";
import AboutAd from "../../../../../components/ProviderAd/AboutAd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { AdContext } from "../../../../../context/AdContext";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ManageAdForm({ ad, setAd, title, onSubmit }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(null);
  const { createAd, newAds, isAdLoading } = AdsApis();
  const [adsData, setAdsData] = useState([]);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSubmit = async () => {
    const service_ids = ad.service_ids.map((ad) => ad.value);
    const massage_ids = ad.massage_ids.map((ad) => ad.value);
    const params = {
      ...ad,
      service_ids: service_ids,
      massage_ids: massage_ids,
    };
    const response = await onSubmit(params);

    if (response.data) {
      navigate("/provider/ads");
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  const getNewAdsData = async () => {
    const response = await newAds();
    if (response.data) {
      setTabValue(0);
      setAdsData(response.data);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  useEffect(() => {
    getNewAdsData();
  }, []);

  if (isAdLoading) return <Loading />;
  if (errorMessage) return null;

  return (
    <AdContext.Provider value={{ travelAd: false }}>
      <div className="myAds">
        <div className="myAds__body">
          <Box>
            <h3>{title}</h3>
          </Box>
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabsChange}
              aria-label="basic tabs example"
              variant="scrollable"
            >
              <Tab label={t("providers.myAd.aboutTab")} {...a11yProps(0)} />
              <Tab label={t("providers.myAd.serviceTab")} {...a11yProps(1)} />

              <Tab
                label={t("providers.myPhoto.myPhotoHeader")}
                {...a11yProps(2)}
              />
              <Tab label={t("providers.myVideo.videos")} {...a11yProps(3)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <AboutAd
              adData={ad}
              setAdData={setAd}
              handleTabsChange={handleTabsChange}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <MassageAndServicesForm
              adsData={adsData}
              adData={ad}
              setAdData={setAd}
              handleTabsChange={handleTabsChange}
            />
          </CustomTabPanel>

          <CustomTabPanel value={tabValue} index={2}>
            <AdPhotos
              handleTabsChange={handleTabsChange}
              adData={ad}
              setAdData={setAd}
            />
          </CustomTabPanel>

          <CustomTabPanel value={tabValue} index={3}>
            <AdVideos
              handleTabsChange={handleTabsChange}
              adData={ad}
              setAdData={setAd}
              onSubmit={handleSubmit}
            />
          </CustomTabPanel>
        </div>
      </div>
    </AdContext.Provider>
  );
}
