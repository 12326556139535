import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  provider: null,
  isproviderAuthenticated: false,
};

export const providerSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    providerData(state, action) {
      const { provider } = action.payload;
      state.provider = provider;
    },
    providerLoginSuccess(state, action) {
      const { provider, token } = action.payload;
      state.provider = provider;
      state.isproviderAuthenticated = true;
      Cookies.set("provider-access-token", token, { expires: 1 });
    },
    providerLogoutSuccess(state) {
      state.provider = null;
      state.isproviderAuthenticated = false;
      Cookies.remove("provider-access-token");
    },
  },
});

export const { providerLoginSuccess, providerLogoutSuccess, providerData } =
  providerSlice.actions;
export default providerSlice.reducer;
