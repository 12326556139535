import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { store } from "../../redux/store";
import { SERVER_API } from "../../helpers/variable";
import Cookies from "js-cookie";
import { onSuccess, onError, formDataToObject } from "./request";
import { clientData } from "../../redux/slices/auth/client";
import { useSelector } from "react-redux";

export default function ProfileSetup() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { language } = useSelector((state) => state.i18n);

  const [isLoading, setIsLoading] = useState(false);

  const sendProfileInfo = async (id, client) => {
    setIsLoading(true);
    const formData = new FormData();

    for (const property in client) {
      if (property == "profile_image") {
        formData.append(property, client[property]);
      } else {
        formData.append(`client[${property}]`, client[property]);
      }
    }

    const response = await axios
      .put(`${SERVER_API}/clients/profile_setup/${id}`, formData, {
        headers: {
          Authorization: Cookies.get("client-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        store.dispatch(
          clientData({
            client: response.data,
          })
        );
        onSuccess(
          response,
          showToast,
          "Profile information saved successfully!"
        );
        navigate(`/client/profileSetup/${response.data.setup_stage}`);
      })
      .catch((error) => {
        onError(error, showToast);
      });
    setIsLoading(false);
    return response;
  };

  const phoneVerification = async (code) => {
    setIsLoading(true);
    const response = await axios
      .put(
        `${SERVER_API}/clients/profile_setup/phone_verification`,
        { code: code },
        {
          headers: {
            Authorization: Cookies.get("client-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        let message = null;
        if (response.data.account_verified) {
          store.dispatch(
            clientData({
              client: response.data,
            })
          );
          debugger;
          onSuccess(response, showToast, "Mobile has been verified");
          navigate(`/`);
        } else {
          showToast({ message: response.data.message, flag: "error" });
        }
      })
      .catch((error) => {
        onError(error, showToast);
      });
    setIsLoading(false);
    return response;
  };

  const resendVerification = async () => {
    setIsLoading(true);
    return await axios
      .get(
        `${SERVER_API}/clients/profile_setup/resend_verification?locale=${language.id}`,
        {
          headers: {
            Authorization: Cookies.get("client-access-token"),
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        onSuccess(response, showToast, "OTP code is sent!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  };

  return {
    sendProfileInfo,
    phoneVerification,
    resendVerification,
    isLoading,
  };
}
