import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Email as EmailIcon } from "@mui/icons-material";
export default function CopyEmailButton({ email }) {
  const [copied, setCopied] = useState(false);
  // Replace this with the email you want to copy

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
  };

  return (
    <CopyToClipboard text={email} onCopy={handleCopy}>
      <Tooltip title={copied ? "Copied!" : "Copy Email"}>
        <IconButton>
          <EmailIcon />
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  );
}
