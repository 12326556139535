import React from "react";
import {
  Star as StarIcon,
  Email as EmailIcon,
  Call as CallIcon,
  FmdGood as FmdGoodIcon,
} from "@mui/icons-material";

import EditProfile from "../components/EditProfile";
import { useTranslation } from "react-i18next";
import { Rating } from "@mui/material";

export default function ProfileInformation({
  profile,
  setProfile,
  setErrorMessage,
}) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section className="provider__container__image">
        <figure className="provider__container__image__box">
          <img
            src={
              profile.profile_image ||
              require("../../../../../assets/body-werx-1.jpeg")
            }
            alt="body-wrex-profile-image"
          />
          <main className="provider__container__image__editIcon">
            <EditProfile
              setErrorMessage={setErrorMessage}
              profile={profile}
              setProfile={setProfile}
            />
          </main>
        </figure>
      </section>
      <h4 className="provider__container__title">
        {profile?.provider?.screen_name}
      </h4>
      <section className="provider__container__rating">
        <Rating
          name="read-only"
          readOnly
          value={profile.provider.review_ratings}
          // activeColor="#ffd700"
        />
      </section>
      <h5 className="provider__container__text">
        {profile.height_unit &&
          profile.height &&
          `${profile.height} ${profile.height_unit}`}{" "}
        |{" "}
        {profile.weight_unit &&
          profile.weight &&
          `${profile.weight} ${profile.weight_unit}`}{" "}
        | {profile?.provider?.age} years old
      </h5>
      <summary className="provider__container__about">
        <section className="provider__container__about__email__container">
          <EmailIcon className="provider__container__about__email__container__icon" />
          <h4>{profile.provider.email}</h4>
        </section>
        <section className="provider__container__about__contact__container">
          <CallIcon className="about__contact__container__callIcon" />
          <figure className="provider__container__about__contact__container__country__image">
            <img src={require("../../../../../assets/NoPath - Copy (2).png")} />
          </figure>
          <h4>{profile.provider.phone}</h4>
        </section>
        <section className="provider__container__about__locationInformation">
          <FmdGoodIcon className="about__locationInformation__cityIcon" />
          <h4>
            {profile.provider.street}
            {profile.provider.country}
          </h4>
        </section>
        <section className="provider__container__about__container">
          <h4>{t("client.title.About")}</h4>
          <div className="provider__container__about__container__paragraph">
            <p>{profile.provider.about}</p>
          </div>
        </section>
      </summary>
    </React.Fragment>
  );
}
