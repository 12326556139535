import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import BlueButton from "../../../components/Form/BlueButton";
import { useSelector } from "react-redux";

const CheckoutForm = ({ price, package_id, frequency_id }) => {
  const { provider } = useSelector((state) => state.providerAuth);

  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `http://localhost:3000/provider/payment/${provider.id}/${package_id}/${frequency_id}`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <div className="paymentPage__form">
      <div className="paymentPage__form__heading">
        <h2>Payment Form</h2>
        <p>Amount: ${price}</p>
      </div>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <div className="paymentPage__form__button">
          <BlueButton
            title="Pay"
            disabled={!stripe}
            onClick={handleSubmit}
          ></BlueButton>
        </div>
      </form>
    </div>
  );
};

export default CheckoutForm;
