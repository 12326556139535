import React from "react";
import VideoTab from "./VideoTab";
export default function Video() {
  return (
    <main className="myvideo">
      <section className="myvideo__container">
        <VideoTab />
      </section>
    </main>
  );
}
