import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ClientPage from "../../../../components/PageWrapper/ClientPage";
import HomeTab from "./components/HomeTab";
import ProviderAd from "./components/ProviderAd";
import { useTranslation } from "react-i18next";
import HomeApis from "../../../../apis/client/HomeApis";
import BodyWerxLoading from "../../../../components/general/BodyWerxLoading";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { useQuery } from "react-query";
import { Helmet, HelmetProvider } from "react-helmet-async";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Home() {
  const { isLoading, getServicesAndMassageTypes, getTopRatedProvidersAds } =
    HomeApis();

  const { data: ads } = useQuery(
    "getTopRatedProvidersAds",
    getTopRatedProvidersAds
  );

  const { data: servicesAndMassageTypes } = useQuery(
    "getServicesAndMassageTypes",
    getServicesAndMassageTypes
  );

  // const [errorMessage, setErrorMessage] = useState(null);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const [ads, setAds] = useState({ top_rated: [], pro_providers: [] });
  // const [servicesAndMassageTypes, setServiceAndMassageTypes] = useState({
  //   massage_types: [],
  //   services: [],
  // });

  // const fetchServicesAndMassageType = async () => {
  //   const response = await getServicesAndMassageTypes();
  //   if (response.errorMessage) {
  //     // setErrorMessage(response.errorMessage);
  //   } else {
  //     setServiceAndMassageTypes(response.data);
  //   }
  // };

  // const fetchAds = async () => {
  //   const response = await getTopRatedProvidersAds();
  //   if (response.errorMessage) {
  //     setErrorMessage(response.errorMessage);
  //   } else {
  //     setAds(response.data);
  //   }
  // };

  // useEffect(() => {
  //   fetchServicesAndMassageType();
  //   // fetchAds();
  // }, []);

  if (isLoading) return <BodyWerxLoading />;
  return (
    <HelmetProvider>
      <ClientPage>
        <Helmet prioritizeSeoTags>
          <title>
            BodyWerx - Find Top Massage Therapists, Trainers, and Wellness
            Experts Near You
          </title>
          <meta
            name="description"
            content="Discover the best massage therapists, personal trainers, nutritionists, and wellness experts in your area with BodyWerx. Book appointments easily and start your wellness journey today. BodyWerx jeff@bodywerx.com "
          />
          <meta
            name="keywords"
            content="Bodywerx, provider, therapist, nutritionist, trainer, health services, gym trainer BodyWerx jeff@bodywerx.com"
          />
          <meta
            property="og:title"
            content="BodyWerx - Find Top Massage Therapists, Trainers, nutritionist and Wellness Experts Near You"
          />
          <meta
            property="og:description"
            content="Discover the best massage therapists, personal trainers, nutritionists, and wellness experts in your area with BodyWerx. Book appointments easily and start your wellness journey today."
          />
          <meta
            property="og:image"
            content={
              <img
                src={require("../../../../assets/logo.jpeg")}
                alt="bodywerx logo"
              />
            }
          />
          <meta property="og:url" content="https://bodywerx.com" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="bodywerx" />
        </Helmet>

        <Box sx={{ width: "100%", marginTop: "5rem" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              TabIndicatorProps={{
                sx: { backgroundColor: "#193D66", height: 2 },
              }}
              sx={{
                marginBottom: "1rem",
                "& button": {
                  color: "#858585",
                  fontWeight: "600",
                  fontSize: "1rem",
                  textTransform: "capitalize",
                  marginRight: "1rem",
                },
                "& button.Mui-selected": {
                  color: "#193D66",
                  fontWeight: "600",
                  fontSize: "1rem",
                },
              }}
            >
              <Tab label={"Home"} {...a11yProps(0)} />
              {servicesAndMassageTypes?.data?.services.map((service, index) => (
                <Tab label={service.name} {...a11yProps(index + 1)} />
              ))}
              {/* {servicesAndMassageTypes.massage_types.map(
              (massage_type, index) => (
                <Tab label={massage_type.name} {...a11yProps(index + 2)} />
              )
            )} */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <HomeTab ads={ads?.data} isLoading={isLoading} />
          </TabPanel>
          {servicesAndMassageTypes?.data?.services.map((service, index) => (
            <TabPanel key={service.id} value={value} index={index + 1}>
              <ProviderAd params={{ service_ids: [service.id] }} />
            </TabPanel>
          ))}
          {/* {servicesAndMassageTypes.massage_types.map((massage_type, index) => (
          <TabPanel key={massage_type.id} value={value} index={index + 2}>
            <ProviderAd params={{ massage_type_ids: [massage_type.id] }} />
          </TabPanel>
        ))} */}
        </Box>
      </ClientPage>
    </HelmetProvider>
  );
}
