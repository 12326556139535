import React, { useState } from "react";
import PropTypes from "prop-types";
import ServiceHourTab from "./ServiceHourTab";
import RateTab from "./RateTab";
import PhotoTab from "./PhotoTab";
import VideoTab from "./VideoTab";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Tabs, Tab, Typography, Box } from "@mui/material";

const CustomizedTabs = styled(Tabs)`
  .css-heg063-MuiTabs-flexContainer {
    display: flex !important;
    justify-content: space-between !important;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProfileTab({ ad }) {
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);
  const [style, setStyle] = useState({
    Services: {
      backgroundColor: "#7fc550",
      color: "white",
      borderRadius: "50px",
    },
    Rates: {
      backgroundColor: "",
      color: "",
      borderRadius: "",
      width: "",
    },
    Photos: {
      backgroundColor: "",
      color: "",
      borderRadius: "",
      width: "",
    },
    Videos: { backgroundColor: "", color: "", borderRadius: "", width: "" },
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = (type) => {
    if (
      type === "Services" ||
      type === "Rates" ||
      type === "Photos" ||
      type === "Videos"
    ) {
      setStyle((prevState) => ({
        prevState,
        [type]: {
          backgroundColor: "#7fc550",
          color: "white",
          borderRadius: "50px",
        },
      }));
    }
  };

  return (
    <Box className="profileTab">
      <Box className="profileTab__container">
        <CustomizedTabs
          value={value}
          onChange={handleChange}
          indicatorColor="transparent"
          variant="scrollable"
        >
          <Tab
            label={t("client.adsProfile.serviceHour")}
            {...a11yProps(0)}
            style={{
              ...style.Services,
              textWrap: "noWrap",
            }}
            onClick={() => handleSubmit("Services")}
            className="serviceHour__tab"
          />
          <Tab
            label={t("client.adsProfile.rate")}
            {...a11yProps(1)}
            style={{
              ...style.Rates,
            }}
            onClick={() => handleSubmit("Rates")}
            className="rate__tab"
          />
          <Tab
            label={t("client.adsProfile.photo")}
            {...a11yProps(2)}
            style={{
              ...style.Photos,
            }}
            onClick={() => handleSubmit("Photos")}
            className="photo__tab"
          />
          <Tab
            label={t("client.adsProfile.video")}
            {...a11yProps(3)}
            style={{
              ...style.Videos,
            }}
            onClick={() => handleSubmit("Videos")}
            className="video__tab"
          />
        </CustomizedTabs>
      </Box>

      <TabPanel value={value} index={0}>
        <ServiceHourTab serviceHours={ad.service_hours} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RateTab services={ad.services} rates={ad.rates} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PhotoTab ad_photos={ad.ad_photos} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <VideoTab ad_videos={ad.ad_videos} />
      </TabPanel>
    </Box>
  );
}
