import React, { useState } from "react";
import { SERVER_API } from "../../helpers/variable";
import Cookies from "js-cookie";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { onSuccess, onError } from "./request";
import { useSelector } from "react-redux";

export default function AdsApis() {
  const { showToast } = useToast();
  const [isAdLoading, setIsAdLoading] = useState(false);
  const { language } = useSelector((state) => state.i18n);

  async function getAds() {
    setIsAdLoading(true);
    return await axios
      .get(`${SERVER_API}/providers/ads?locale=${language.id}`, {
        headers: {
          Authorization: Cookies.get("provider-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        setIsAdLoading(false);
        onSuccess(response, showToast, "Data fetched!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsAdLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function newAds() {
    setIsAdLoading(true);
    return await axios
      .get(`${SERVER_API}/providers/ads/new?locale=${language.id}`, {
        headers: {
          Authorization: Cookies.get("provider-access-token"),
        },
      })
      .then((response) => {
        setIsAdLoading(false);
        onSuccess(response, showToast, "New Ad created");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsAdLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }
  async function createAd(ad) {
    setIsAdLoading(true);
    return await axios
      .post(`${SERVER_API}/providers/ads?locale=${language.id}`, ad, {
        headers: {
          Authorization: Cookies.get("provider-access-token"),
        },
      })
      .then((response) => {
        setIsAdLoading(false);
        showToast({ message: response.data.message, flag: "success" });
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsAdLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function getAd(id) {
    setIsAdLoading(true);
    return await axios
      .get(`${SERVER_API}/providers/ads/${id}?locale=${language.id}`, {
        headers: {
          Authorization: Cookies.get("provider-access-token"),
        },
      })
      .then((response) => {
        setIsAdLoading(false);
        onSuccess(response, showToast, "Ad fetched");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsAdLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function updateAd(ad, id) {
    setIsAdLoading(true);
    return await axios
      .put(`${SERVER_API}/providers/ads/${id}`, ad, {
        headers: {
          Authorization: Cookies.get("provider-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        setIsAdLoading(false);
        onSuccess(response, showToast, "Updated ads!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        setIsAdLoading(false);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  return {
    getAd,
    newAds,
    getAds,
    createAd,
    updateAd,
    isAdLoading,
  };
}
