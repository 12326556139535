import React from "react";

const GreenInput = ({
  label,
  placeholder,
  value,
  required,
  name,
  handleChange,
  register
}) => {
  return (
    <div className="greenTextarea">
      <label>{label}</label>
      <div className="greenTextarea__textBox">
        <textarea
          name={name}
          placeholder={placeholder}
          value={value}
          required={required}
          rows={3}
          onChange={(e) => handleChange(e)}
          {...register}
        />
      </div>
    </div>
  );
};

export default GreenInput;
