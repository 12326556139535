import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setSidebarId } from "../../redux/slices/sidebarSlice";
import { sidebarItems } from "../../redux/slices/sidebarSlice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

function ProviderSidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const sideBarId = useSelector((state) => state.sidebar.sideBarId);

  const handleClick = (item) => {
    dispatch(setSidebarId(item.id));
    navigate(item.path);
  };

  return (
    <aside className="provider__sidebar__section">
      {sidebarItems.map((item, index) => (
        <section
          key={item.id}
          className="provider__sidebar__section__myprofile"
          onClick={() => handleClick(item)}
          style={{
            background: item.id === sideBarId ? "#476485" : "",
            borderRadius: "6px",
          }}
        >
          <div className="provider__sidebar__section__myprofile__icon">
            {item.icon}
          </div>
          <h4 className="provider__sidebar__section__myprofile__name">
            {t(item.name)}
          </h4>
        </section>
      ))}
    </aside>
  );
}

export default ProviderSidebar;
