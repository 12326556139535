import React from "react";
import PropTypes from "prop-types";
import { Card, Typography, Skeleton, Stack } from "@mui/material";
import { Favorite as FavoriteIcon } from "@mui/icons-material";
import { useMediaQuery, useTheme } from "@mui/material";

function Media(props) {
  const { loading = false } = props;

  const card = [
    {
      img: <Skeleton variant="rounded" width={100} height={100} />,
      title: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      rating: (
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width={130}
          height={20}
        />
      ),
      weigth: (
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width={130}
          height={20}
        />
      ),
      border: <Skeleton variant="text" sx={{ fontSize: "1rem" }} height={10} />,
      serviceTitle: (
        <Typography variant="h4" width={150}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      services: (
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} height={25} />
      ),
      location: (
        <Typography variant="h4">{loading ? <Skeleton /> : "h4"}</Typography>
      ),
      imageTitle: (
        <Typography variant="h4" width={150}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      imageCrousel: <Skeleton variant="rounded" width={150} height={150} />,
      reviews: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      serviceCompleted: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      startingPrice: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      price: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      icon: <FavoriteIcon color="disabled" />,
    },
    {
      img: <Skeleton variant="rounded" width={100} height={100} />,
      title: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      rating: (
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width={130}
          height={20}
        />
      ),
      weigth: (
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width={130}
          height={20}
        />
      ),
      border: <Skeleton variant="text" sx={{ fontSize: "1rem" }} height={10} />,
      serviceTitle: (
        <Typography variant="h4" width={150}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      services: (
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} height={25} />
      ),
      location: (
        <Typography variant="h4">{loading ? <Skeleton /> : "h4"}</Typography>
      ),
      imageTitle: (
        <Typography variant="h4" width={150}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      imageCrousel: <Skeleton variant="rounded" width={150} height={150} />,
      reviews: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      serviceCompleted: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      startingPrice: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      price: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      icon: <FavoriteIcon color="disabled" />,
    },
    {
      img: <Skeleton variant="rounded" width={100} height={100} />,
      title: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      rating: (
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width={130}
          height={20}
        />
      ),
      weigth: (
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width={130}
          height={20}
        />
      ),
      border: <Skeleton variant="text" sx={{ fontSize: "1rem" }} height={10} />,
      serviceTitle: (
        <Typography variant="h4" width={150}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      services: (
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} height={25} />
      ),
      location: (
        <Typography variant="h4">{loading ? <Skeleton /> : "h4"}</Typography>
      ),
      imageTitle: (
        <Typography variant="h4" width={150}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      imageCrousel: <Skeleton variant="rounded" width={150} height={150} />,
      reviews: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      serviceCompleted: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      startingPrice: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      price: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      icon: <FavoriteIcon color="disabled" />,
    },
    {
      img: <Skeleton variant="rounded" width={100} height={100} />,
      title: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      rating: (
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width={130}
          height={20}
        />
      ),
      weigth: (
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width={130}
          height={20}
        />
      ),
      border: <Skeleton variant="text" sx={{ fontSize: "1rem" }} height={10} />,
      serviceTitle: (
        <Typography variant="h4" width={150}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      services: (
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} height={25} />
      ),
      location: (
        <Typography variant="h4">{loading ? <Skeleton /> : "h4"}</Typography>
      ),
      imageTitle: (
        <Typography variant="h4" width={150}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      imageCrousel: <Skeleton variant="rounded" width={150} height={150} />,
      reviews: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      serviceCompleted: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      startingPrice: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      price: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      icon: <FavoriteIcon color="disabled" />,
    },
    {
      img: <Skeleton variant="rounded" width={100} height={100} />,
      title: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      rating: (
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width={130}
          height={20}
        />
      ),
      weigth: (
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem" }}
          width={130}
          height={20}
        />
      ),
      border: <Skeleton variant="text" sx={{ fontSize: "1rem" }} height={10} />,
      serviceTitle: (
        <Typography variant="h4" width={150}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      services: (
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} height={25} />
      ),
      location: (
        <Typography variant="h4">{loading ? <Skeleton /> : "h4"}</Typography>
      ),
      imageTitle: (
        <Typography variant="h4" width={150}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      imageCrousel: <Skeleton variant="rounded" width={150} height={150} />,
      reviews: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      serviceCompleted: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      startingPrice: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      price: (
        <Typography variant="h4" width={130}>
          {loading ? <Skeleton /> : "h4"}
        </Typography>
      ),
      icon: <FavoriteIcon color="disabled" />,
    },
  ];

  const theme = useTheme();
  const isXLarge = useMediaQuery("(min-width: 1800px)");
  const isLarge = useMediaQuery("(min-width: 1600px) and (max-width: 1799px)");
  const isMedium = useMediaQuery("(min-width: 1000px) and (max-width: 1599px)");
  const isSmall = useMediaQuery("(min-width: 800px) and (max-width: 999px)");
  const isXSmall = useMediaQuery("(min-width: 400px) and (max-width: 799px)");

  let slidesPerView;
  if (isXLarge) {
    slidesPerView = 5;
  } else if (isLarge) {
    slidesPerView = 4;
  } else if (isMedium) {
    slidesPerView = 3;
  } else if (isSmall) {
    slidesPerView = 2;
  } else {
    slidesPerView = 1;
  }

  return (
    <React.Fragment>
      {card.slice(0, slidesPerView).map((item, index) => (
        <Card
          sx={{
            padding: "1rem",
          }}
          key={index}
        >
          <Stack spacing={0.5}>
            <div className="flex">
              {item.img}
              <div>
                {item.title}
                {item.rating}
                {item.weigth}
              </div>
              {item.icon}
            </div>
            {item.border}
            {item.serviceTitle}
            {item.location}

            <div style={{ display: "flex", gap: "1rem" }}>
              {item.imageCrousel}
              {item.imageCrousel}
            </div>
            {item.border}
            <div className="flex">
              {item.reviews}
              {item.serviceCompleted}
            </div>
            <div className="flex">
              {item.startingPrice}
              {item.price}
            </div>
          </Stack>
        </Card>
      ))}
    </React.Fragment>
  );
}

Media.propTypes = {
  loading: PropTypes.bool,
};

export default function SkeletonCard() {
  return (
    <div className="skeleton" style={{ gap: "1rem" }}>
      <Media loading />
    </div>
  );
}
