import React, { useEffect } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
const Message = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (window.innerWidth > 768) {
      navigate("/chat");
    }
  }, []);
  return (
    <section className="message">
      <div className="message__profileContainer">
        <div
          className="message__profileContainer__icon"
          onClick={() => navigate("/chat")}
        >
          <ArrowBackIosNewIcon fontSize="small" sx={{ fontSize: "17px" }} />
        </div>
        <div className="message__profileContainer__image">
          <img src={require("../../../assets/NoPath - Copy (228).png")} />
        </div>
        <div className="message__profileContainer__namecontainer">
          <div className="message__profileContainer__namecontainer__name">
            <span>john Doe</span>
          </div>
          <div className="message__profileContainer__namecontainer__lastseen">
            <p>Last seen 3h ago</p>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: "1rem",
          padding: ".5rem",
        }}
      >
        <div className="message__receiver">
          <div className="message__receiver__paragraph">
            <p>Hello</p>
          </div>
          <div className="message__receiver__timeStamp">
            <span>10:50pm</span>
          </div>
        </div>
        <div className="message__sender">
          <div className="message__sender__paragraph">
            <p>Hello</p>
          </div>
          <div className="message__sender__timeStamp">
            <span>10:50pm</span>
          </div>
        </div>
        <div className="message__receiver">
          <div className="message__receiver__paragraph">
            <p>How are you</p>
          </div>
          <div className="message__receiver__timeStamp">
            <span>10:50pm</span>
          </div>
        </div>
        <div className="message__sender">
          <div className="message__sender__paragraph">
            <p>I'm good, how are you?</p>
          </div>
          <div className="message__sender__timeStamp">
            <span>10:50pm</span>
          </div>
        </div>
      </div>

      <div className="message__sendMessageContainer">
        {/* <div className="message__sendMessageContainer__attachfileicon">
          <AttachFileIcon   />
        </div> */}
        <div className="message__sendMessageContainer__maincontainer">
          <div className="message__sendMessageContainer__maincontainer__textfiled">
            <input type="text" placeholder="Write a message" />
          </div>
          <SendIcon className="message__sendMessageContainer__maincontainer__sendicon" />
        </div>
      </div>
    </section>
  );
};
export default Message;
