import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { SERVER_API } from "../../helpers/variable";
import Cookies from "js-cookie";
import { onSuccess, onError, formDataToObject } from "./request";
import { store } from "../../redux/store";
import {
  providerLogoutSuccess,
  providerData,
} from "../../redux/slices/auth/provider";
import { setSubscription } from "../../redux/slices/subscription";
import { useSelector } from "react-redux";

export default function ProfileApis() {
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const { language } = useSelector((state) => state.i18n);
  const navigate = useNavigate();
  const { showToast } = useToast();

  async function getProfile() {
    setIsProfileLoading(true);
    return await axios
      .get(`${SERVER_API}/providers/profile?locale=${language.id}`, {
        headers: {
          Authorization: Cookies.get("provider-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        setIsProfileLoading(false);
        onSuccess(response, showToast, "Data fetched!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsProfileLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function updatePersonalInformation(provider) {
    setIsProfileLoading(true);
    const formData = new FormData();
    for (const property in provider) {
      if (property == "profile_image") {
        formData.append(property, provider[property]);
      } else {
        formData.append(`provider[${property}]`, provider[property]);
      }
    }

    return await axios
      .put(
        `${SERVER_API}/providers/profile/update_personal_information?locale=${language.id}`,
        formData,
        {
          headers: {
            Authorization: Cookies.get("provider-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Updated provider information!");
        setIsProfileLoading(false);
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsProfileLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function updateproviderAddress(address) {
    return await axios
      .put(
        `${SERVER_API}/providers/profile/update_address?locale=${language.id}`,
        { provider: { ...address } },
        {
          headers: {
            Authorization: Cookies.get("provider-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Updated provider address!");
        return response.data;
      })
      .catch((error) => {
        onError(error, showToast);
      });
  }

  async function updatePassword(params) {
    setIsProfileLoading(true);

    const response = await axios
      .put(
        `${SERVER_API}/providers/profile/update_password`,
        { provider: { ...params } },
        {
          headers: {
            Authorization: Cookies.get("provider-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Password Updated Successfully!");
        store.dispatch(providerLogoutSuccess());
        navigate("/signin");
        setIsProfileLoading(false);
      })
      .catch((error) => {
        onError(error, showToast);
        setIsProfileLoading(false);
      });
  }

  const sendServicesAndMassageTypes = async (params) => {
    setIsProfileLoading(true);
    const response = await axios
      .put(
        `${SERVER_API}/providers/profile/update_massage_and_services?locale=${language.id}`,
        { ...params },
        {
          headers: {
            Authorization: Cookies.get("provider-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Updated Services and Massage Types");
        return response.data;
      })
      .catch((error) => {
        onError(error, showToast);
      });
    setIsProfileLoading(false);
  };

  const updateproviderDetails = async (provider_details) => {
    setIsProfileLoading(true);
    const formData = new FormData();
    for (const property in provider_details) {
      if (property == "profile_image") {
        formData.append(property, provider_details[property]);
      }
      if (property == "provider") {
        const provider = provider_details[property];
        for (const entry in provider) {
          formData.append(`provider[${entry}]`, provider[entry]);
        }
      } else {
        if (property == "provider_detail") {
          const provider_detail = provider_details[property];
          for (const provider_d in provider_detail) {
            formData.append(
              `provider_detail[${provider_d}]`,
              provider_detail[provider_d]
            );
          }
        }
      }
    }
    return await axios
      .put(
        `${SERVER_API}/providers/profile/update_provider_and_provider_details?locale=${language.id}`,
        formData,
        {
          headers: {
            Authorization: Cookies.get("provider-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        setIsProfileLoading(false);
        onSuccess(response, showToast, "provider Data is updated!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsProfileLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  };

  const providerSignOut = async () => {
    setIsProfileLoading(true);
    await axios
      .delete(`${SERVER_API}/providers/logout`, {
        headers: {
          Authorization: Cookies.get("provider-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Logout Successfully!");
        store.dispatch(providerLogoutSuccess());
        navigate("/signin");
        setIsProfileLoading(false);
      })
      .catch((error) => {
        setIsProfileLoading(false);
        onError(error, showToast);
      });
  };

  async function updateProviderDemographics(demographic) {
    return await axios
      .put(
        `${SERVER_API}/providers/profile/demographics_details?locale=${language.id}`,
        demographic,
        {
          headers: {
            Authorization: Cookies.get("provider-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Updated provider demographics!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function updatePersonalization(personalization) {
    return await axios
      .put(
        `${SERVER_API}/providers/profile/update_personalization?locale=${language.id}`,
        { provider_detail: personalization },
        {
          headers: {
            Authorization: Cookies.get("provider-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Updated provider personalization!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function getPersonalization() {
    setIsProfileLoading(true);

    return await axios
      .get(
        `${SERVER_API}/providers/profile/get_personlization?locale=${language.id}`,
        {
          headers: {
            Authorization: Cookies.get("provider-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        setIsProfileLoading(false);
        onSuccess(response, showToast, "get provider personalization!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        setIsProfileLoading(false);
      });
  }
  async function getCurrentSubscription() {
    setIsProfileLoading(true);

    return await axios
      .get(
        `${SERVER_API}/providers/profile/current_subscription?locale=${language.id}`,
        {
          headers: {
            Authorization: Cookies.get("provider-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        const sub = response.data;
        store.dispatch(
          setSubscription({
            package_id: sub.package_id,
            amount: sub.amount,
            frequency: sub.frequency,
            active: sub.active,
            expire_date: sub.expire_date,
          })
        );
        setIsProfileLoading(false);
        onSuccess(response, showToast, "get provider subscription!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        setIsProfileLoading(false);
      });
  }

  async function currentProfile() {
    setIsProfileLoading(true);
    return await axios
      .get(
        `${SERVER_API}/providers/profile/current_profile?locale=${language.id}`,
        {
          headers: {
            Authorization: Cookies.get("provider-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        setIsProfileLoading(false);
        // onSuccess(response, showToast, "Data fetched!");
        store.dispatch(
          providerData({
            provider: response.data,
          })
        );
      })
      .catch((error) => {
        setIsProfileLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  return {
    getProfile,
    updateproviderAddress,
    updatePassword,
    sendServicesAndMassageTypes,
    updateproviderDetails,
    providerSignOut,
    updateProviderDemographics,
    getPersonalization,
    updatePersonalization,
    getCurrentSubscription,
    currentProfile,
    updatePersonalInformation,
    isProfileLoading,
  };
}
