import React, { useState, useEffect } from "react";
import ClientPage from "../../../../components/PageWrapper/ClientPage";
import ProviderTabs from "./components/ProviderTabs";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ProviderProfile from "./components/ProviderProfile/ProviderProfile";
import BodyWrrxLoading from "../../../../components/general/BodyWerxLoading";
import ProviderAds from "../../../../apis/client/ProviderAds";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ProviderPortfolio() {
  const { t } = useTranslation();
  const { provider_slug } = useParams();
  const { providerProfile, isLoading } = ProviderAds();
  const [profile, setProfile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchProviderProfile = async () => {
    const response = await providerProfile(provider_slug);

    if (response.error) {
      setErrorMessage(response.errorMessage);
    } else {
      setProfile(response.data);
    }
  };
  useEffect(() => {
    fetchProviderProfile();
  }, []);

  if (isLoading) return <BodyWrrxLoading />;
  if (errorMessage) return null;

  return (
    <ClientPage>
      {profile ? (
        <main className="clientPorfile">
          <ProviderProfile profile={profile} />
          <section className="clientPorfile__clientPortfolio">
            <ProviderTabs profile={profile} />
          </section>
          <section className="clientPorfile__messageIcon">
            <QuestionAnswerIcon />
          </section>
        </main>
      ) : (
        <h1 className="noVideo__title">{t("client.title.dataloaded")}</h1>
      )}
    </ClientPage>
  );
}
