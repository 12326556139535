import React from "react";

function DaysAgo({ date }) {
  const calculateDaysAgo = (dateString) => {
    const dateProvided = new Date(dateString);
    const currentDate = new Date();
    const differenceInTime = currentDate - dateProvided; // difference in milliseconds
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    const differenceInWeeks = Math.floor(differenceInDays / 7);
    const differenceInMonths = Math.floor(differenceInDays / 30); // Approximation
    const differenceInYears = Math.floor(differenceInMonths / 12);

    if (differenceInDays < 7) {
      if (differenceInDays == 0) {
        return "Today";
      }
      return `${differenceInDays} day${differenceInDays !== 1 ? "s" : ""} ago`;
    } else if (differenceInWeeks < 4) {
      return `${differenceInWeeks} week${
        differenceInWeeks !== 1 ? "s" : ""
      } ago`;
    } else if (differenceInMonths < 12) {
      return `${differenceInMonths} month${
        differenceInMonths !== 1 ? "s" : ""
      } ago`;
    } else {
      return `${differenceInYears} year${
        differenceInYears !== 1 ? "s" : ""
      } ago`;
    }
  };

  return <div>{calculateDaysAgo(date)}</div>;
}

export default DaysAgo;
