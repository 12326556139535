import React from "react";

const GreenSelect = ({ name, value, label, options, handleChange,register }) => {
  return (
    <div className="greenSelect">
      <label>{label}</label>
      <select name={name} value={value} onChange={(e) => handleChange(e)}  {...register}>
        {options.map((item) => (
          <option value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>
  );
};

export default GreenSelect;
