import React from "react";

export default function Images({ images }) {
  return (
    <>
      {images.map((image) => (
        <img src={image} alt="images" />
      ))}
    </>
  );
}
