import React, { useState } from "react";
import { Box, Modal, IconButton } from "@mui/material";
import {
  TaskAlt as TaskAltIcon,
  PriorityHigh as PriorityHighIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "none !important",
  boxShadow: 24,
  borderRadius: "10px",
  width: 750,
  height: "400px",
};

export default function Popup({ open, handleClose }) {
  const { t } = useTranslation();
  return (
    <main>
      {/* <header className="provider__profilesetting__section__box__container">
        <div className="provider__profilesetting__section__box__container__icon">
          <ArrowForwardIosIcon fontSize="small" />
        </div>
      </header> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(15px)",
          WebkitBackdropFilter: "blur(15px)",
        }}
      >
        <Box sx={style} className="profilesection">
          <section className="popup">
            <div className="background">
              <div className=" cross__icon">
                <IconButton onClick={handleClose}>
                  <CloseIcon sx={{ color: "white" }} fontSize="large" />
                </IconButton>
              </div>
              <h1> {t("providers.popupModal.welcome")}</h1>
            </div>
            <div className="popup__right">
              <aside className="plan__list">
                <TaskAltIcon sx={{ color: "#0AB361 " }} />
                <h4> {t("providers.popupModal.profileInformation")}</h4>
              </aside>
              <aside className="plan__list">
                <TaskAltIcon sx={{ color: "#0AB361 " }} />
                <h4> {t("providers.popupModal.editDemoGraphice")}</h4>
              </aside>
              <aside className="plan__list">
                <TaskAltIcon sx={{ color: "#0AB361 " }} />
                <h4> {t("providers.popupModal.editPersonalization")}</h4>
              </aside>
              <aside className="plan__list">
                <TaskAltIcon sx={{ color: "#0AB361 " }} />
                <h4>{t("providers.popupModal.createAds")}</h4>
              </aside>
              <aside className="plan__list">
                <TaskAltIcon sx={{ color: "#0AB361 " }} />
                <h4>{t("providers.popupModal.addRate")}</h4>
              </aside>
              <aside className="plan__list">
                <TaskAltIcon sx={{ color: "#0AB361 " }} />
                <h4>{t("providers.popupModal.addServiceRate")}</h4>
              </aside>
            </div>
          </section>
        </Box>
      </Modal>
    </main>
  );
}
