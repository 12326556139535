import React, { useState } from "react";
import { SERVER_API } from "../../helpers/variable";
import Cookies from "js-cookie";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { onSuccess, onError } from "./request";
import { useSelector } from "react-redux";

export default function RateApis() {
  const { showToast } = useToast();
  const [isRateLoading, setIsRateLoading] = useState(false);
  const { language } = useSelector((state) => state.i18n);

  async function getRates() {
    setIsRateLoading(true);
    return await axios
      .get(`${SERVER_API}/providers/rates?locale=${language.id}`, {
        headers: {
          Authorization: Cookies.get("provider-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        setIsRateLoading(false);
        onSuccess(response, showToast, "Data fetched!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        setIsRateLoading(false);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function addRate(rate) {
    return await axios
      .post(`${SERVER_API}/providers/rates?locale=${language.id}`, rate, {
        headers: {
          Authorization: Cookies.get("provider-access-token"),
        },
      })
      .then((response) => {
        onSuccess(response, showToast, "Added new rate!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function updateRate(rate) {
    return await axios
      .put(
        `${SERVER_API}/providers/rates/${rate.rate.id}?locale=${language.id}`,
        rate,
        {
          headers: {
            Authorization: Cookies.get("provider-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Data Updated!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }
  async function deleteRate(id) {
    setIsRateLoading(true);
    return await axios
      .delete(`${SERVER_API}/providers/rates/${id}`, {
        headers: {
          Authorization: Cookies.get("provider-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        onSuccess(response, showToast, "Rate is deleted successfully");
        setIsRateLoading(false);
        return {
          data: true,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsRateLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }
  return {
    addRate,
    isRateLoading,
    getRates,
    updateRate,
    deleteRate,
  };
}
