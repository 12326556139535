import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ProfileApis from "../../../../../apis/provider/ProfileApis";
import GreenInput from "../../../../../components/Form/GreenInput";
import BlueButton from "../../../../../components/Form/BlueButton";
import { IconButton } from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
const schema = yup.object().shape({
  current_password: yup.string().required("Current password is required"),
  password: yup.string().required("Password is required"),
  confirmation_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "New Password not match match")
    .required("Conrmation Password is requird"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  width: "30%",
};

export default function ChangePassword({ title }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [provider, setprovider] = useState({
    current_password: null,
    password: null,
    confirmation_password: null,
  });
  const [passwordVisible, setPasswordVisible] = useState({
    current_password: false,
    password: false,
    confirmation_password: false,
  });
  const [passwordNotMatch, setPasswordNotMatch] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      current_password: provider.current_password,
      password: provider.password,
      confirmation_password: provider.confirmation_password,
    },
  });
  const { isProfileLoading, updatePassword } = ProfileApis();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setprovider({ ...provider, [name]: value });
  };

  const onSubmit = async (data) => {
    await updatePassword(data);
  };

  useEffect(() => {
    if (
      provider.current_password &&
      provider.confirmation_password === provider.password &&
      provider.password != null
    ) {
      setPasswordNotMatch(false);
    } else {
      setPasswordNotMatch(true);
    }
  }, [provider]);

  const handleClick = (field) => {
    setPasswordVisible((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  return (
    <main>
      {title === "change Password" ? (
        <header
          className="provider__profilesetting__section__box__container"
          onClick={handleOpen}
        >
          <div className="provider__profilesetting__section__box__container__title">
            {t("providers.myProfile.changepassword")}
          </div>
          <div className="provider__profilesetting__section__box__container__icon">
            <ArrowForwardIosIcon fontSize="small" />
          </div>
        </header>
      ) : (
        <div onClick={handleOpen}> {t("client.adsProfile.reset")} </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(15px)",
          WebkitBackdropFilter: "blur(15px)",
        }}
      >
        <Box sx={style} className="profilesection">
          <form onSubmit={handleSubmit(onSubmit)}>
            <section className="modal__container">
              <div className="modal__container__icon" onClick={handleClose}>
                <ArrowBackIosIcon />
              </div>
              <h4 className="passwordChange__container__passChange">
                {title === "change Password"
                  ? t("providers.myProfile.changepassword")
                  : "Reset Password"}
              </h4>
            </section>
            <GreenInput
              handleChange={handleChange}
              label={t("providers.myProfile.currentPassword")}
              type={passwordVisible.current_password ? "text" : "password"}
              name="current_password"
              register={register("current_password")}
              error={errors.current_password?.message}
              icon={
                passwordVisible.current_password ? (
                  <IconButton onClick={() => handleClick("current_password")}>
                    <VisibilityOffOutlinedIcon className="passwordChange__container__passIcon" />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleClick("current_password")}>
                    <VisibilityOutlinedIcon className="passwordChange__container__passIcon" />
                  </IconButton>
                )
              }
            />
            {errors.current_password && (
              <p className="error">{errors.current_password?.message}</p>
            )}
            <GreenInput
              label={t("providers.myProfile.newPassword")}
              type={passwordVisible.password ? "text" : "password"}
              handleChange={handleChange}
              name="password"
              register={register("password")}
              error={errors.password?.message}
              icon={
                passwordVisible.password ? (
                  <IconButton onClick={() => handleClick("password")}>
                    <VisibilityOffOutlinedIcon className="passwordChange__container__passIcon" />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleClick("password")}>
                    <VisibilityOutlinedIcon className="passwordChange__container__passIcon" />
                  </IconButton>
                )
              }
            />
            {errors.password && (
              <p className="error">{errors.password?.message}</p>
            )}

            <GreenInput
              label={t("providers.myProfile.confirmPassword")}
              type={passwordVisible.confirmation_password ? "text" : "password"}
              handleChange={handleChange}
              name="confirmation_password"
              register={register("confirmation_password")}
              error={errors.confirmation_password?.message}
              icon={
                passwordVisible.confirmation_password ? (
                  <IconButton
                    onClick={() => handleClick("confirmation_password")}
                  >
                    <VisibilityOffOutlinedIcon className="passwordChange__container__passIcon" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleClick("confirmation_password")}
                  >
                    <VisibilityOutlinedIcon className="passwordChange__container__passIcon" />
                  </IconButton>
                )
              }
            />
            {errors.confirmation_password && (
              <p className="error">{errors.confirmation_password?.message}</p>
            )}
            <BlueButton
              loading={isProfileLoading}
              disabled={passwordNotMatch}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              className="passwordChange__container__saveButton"
              title={t("providers.myProfile.saveChanges")}
            />
          </form>
        </Box>
      </Modal>
    </main>
  );
}
