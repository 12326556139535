import React, { useEffect, useState } from "react";
import GreenInput from "../../../../../components/Form/GreenInput";
import useRate from "../../../../../apis/provider/RateApis";
import ServiceApis from "../../../../../apis/provider/ServiceApis";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import BlueButton from "../../../../../components/Form/BlueButton";
import { Box, Button, Modal } from "@mui/material";
import {
  Edit as EditIcon,
  ArrowBackIos as ArrowBackIosIcon,
} from "@mui/icons-material";
const schema = yup.object().shape({
  id: yup.string().default(() => uuidv4()),
  price: yup.number().required("Price is required"),
  time_duration: yup.string().required("Time Duration is required"),
  description: yup.string().required("Description is Required"),
  rate_type: yup.string().required("Rate Type is Required"),
  service_id: yup.number().required("Service is Required"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

export default function AddRateModal({ title, fetchRates, rateParams }) {
  const { t } = useTranslation();
  const { isRateLoading, addRate, updateRate } = useRate();
  const { isServiceLoading, getServices } = ServiceApis();
  const [open, setOpen] = React.useState(false);
  const [services, setServices] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [rate, setRate] = useState(rateParams);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: rate.id || uuidv4(),
      price: rate.price,
      rate_type: rate.rate_type,
      time_duration: rate.time_duration,
      service_id: rate.service_id,
      description: rate.description,
    },
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRate((pervState) => ({
      ...pervState,
      [name]: value,
    }));
  };

  const fetchServices = async () => {
    const response = await getServices();
    if (response.error) {
      setErrorMessage(response.errorMessage);
    } else {
      setServices(response.data);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const response =
      rateParams.id != null
        ? await updateRate({ rate: data })
        : await addRate({ rate: data });
    if (response.error) {
      setErrorMessage(response.errorMessage);
    } else {
      fetchRates();
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchServices();
  }, []);

  // if (isRateLoading) return <Loading />;
  // if (errorMessage) return null;

  return (
    <section>
      {title == "Add New Rate" ? (
        <Button
          className="rate__tablecontainer__tableheader__container__buttonbox__button"
          onClick={handleOpen}
        >
          {t("providers.myRate.next")}
        </Button>
      ) : (
        <EditIcon sx={{ color: "#222222" }} onClick={handleOpen} />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(15px)",
          WebkitBackdropFilter: "blur(15px)",
        }}
      >
        <Box sx={style} className="profilesection">
          <header className="modal__container">
            <div className="modal__container__icon" onClick={handleClose}>
              <ArrowBackIosIcon />
            </div>

            <h4 className="passwordChange__container__passChange">
              {title == "Add New Rate"
                ? t("providers.myRate.next")
                : "Edit Rate"}
            </h4>
          </header>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="greenSelect">
              <label>{t("providers.myRate.rateType")}</label>

              <select
                onChange={handleChange}
                name="rate_type"
                {...register("rate_type")}
              >
                <option>{t("providers.myRate.rateType")}</option>
                <option value="incall">{t("providers.myRate.incall")}</option>
                <option value="outcall">{t("providers.myRate.outcall")}</option>
                <option value="other">{t("providers.myRate.other")}</option>
              </select>
            </div>

            <GreenInput
              name={"price"}
              label={t("providers.myRate.price")}
              placeholder={t("providers.myRate.price")}
              type={"number"}
              handleChange={handleChange}
              error={errors.price?.message}
              register={register("price")}
            />

            <GreenInput
              label={t("providers.myRate.time")}
              placeholder={t("providers.myRate.time")}
              type={"number"}
              name={"time_duration"}
              handleChange={handleChange}
              error={errors.time_duration?.message}
              register={register("time_duration")}
            />

            <div className="greenSelect">
              <label>{t("providers.myRate.service")}</label>
              <select
                onChange={handleChange}
                name="service_id"
                {...register("service_id")}
              >
                <option>{t("providers.myRate.service")}</option>
                {services.map((service) => (
                  <option value={service.id}>{service.name}</option>
                ))}
              </select>
            </div>
            <GreenInput
              label={t("providers.myRate.description")}
              placeholder={t("providers.myRate.description")}
              type={"text"}
              name={"description"}
              handleChange={handleChange}
              error={errors.description?.message}
              register={register("description")}
            />
            <BlueButton
              variant="contained"
              className="editProfile__container__saveButton"
              onClick={handleSubmit(onSubmit)}
              loading={loading}
              title={
                title == "Add New Rate"
                  ? t("providers.myProfile.saveChanges")
                  : "Update"
              }
            />
          </form>
        </Box>
      </Modal>
    </section>
  );
}
