import React from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useTranslation } from "react-i18next";

const Search = ({ fetchAds, handleChange }) => {
  const { t } = useTranslation();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      fetchAds();
    }
  };

  return (
    <section className="landingPage__container__searchBox">
      <aside className="landingPage__container__searchBox__search">
        <SearchOutlinedIcon className="landingPage__container__searchBox__search__searchIcon" />
        <input
          type="text"
          name="text"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={t("client.landingPage.searchService")}
          className="landingPage__container__searchBox__search__searchInput"
        />
      </aside>
    </section>
  );
};

export default Search;
