import React, { useEffect, useState } from "react";
import GeneralApis from "../../../../../apis/GeneralApis";
import Loading from "../../../../../components/general/Loading";
import GreenMultiSelect from "../../../../../components/Form/GreenMultiSelect";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import HomeApis from "../../../../../apis/client/HomeApis";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import BlueButton from "../../../../../components/Form/BlueButton";
const Filter = ({
  setAds,
  search,
  filterValues,
  setFilterValues,
  toggleDrawer,
  anchor,
}) => {
  const { t } = useTranslation();
  const { isApiLoading, getDemographics } = GeneralApis();
  const { isLoading, getAds } = HomeApis();
  const [demographics, setDemographics] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchDemographics = async () => {
    const response = await getDemographics();
    if (response.data) {
      setDemographics(response.data);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  const handleChange = (data, name) => {
    setFilterValues((prevState) => ({ ...prevState, [name]: data }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const work_surface_ids = filterValues.work_surface_ids.map(
      (surface) => surface.value
    );

    const work_location_ids = filterValues.work_location_ids.map(
      (location) => location.value
    );
    const sexual_orientation_ids = filterValues.sexual_orientation_ids.map(
      (sexual) => sexual.value
    );
    const ethnicity_ids = filterValues.ethnicity_ids.map(
      (ethnicity) => ethnicity.value
    );
    const language_ids = filterValues.language_ids.map(
      (language) => language.value
    );
    const drug_frequency_ids = filterValues.drug_frequency_ids.map(
      (drug_frequency) => drug_frequency.value
    );
    const smoking_frequency_ids = filterValues.smoking_frequency_ids.map(
      (smoking) => smoking.value
    );

    const response = await getAds({
      ...search,
      ...filterValues,
      work_surface_ids: work_surface_ids,
      smoking_frequency_ids: smoking_frequency_ids,
      drug_frequency_ids: drug_frequency_ids,
      language_ids: language_ids,
      ethnicity_ids: ethnicity_ids,
      sexual_orientation_ids: sexual_orientation_ids,
      work_location_ids: work_location_ids,
    });
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      setAds(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDemographics();
  }, []);

  if (isApiLoading || isLoading)
    return (
      <div
        style={{
          width: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loading />
      </div>
    );
  if (errorMessage) return null;

  return (
    <main className="filter">
      <Box className="filter__header">
        <div
          className="provider__profilesetting__section__box__container__icon"
          onClick={toggleDrawer(anchor, false)}
        >
          <ArrowBackIosIcon fontSize="small" />
        </div>
        <h2 className="filter__header__title">
          {t("client.adsProfile.filter")}
        </h2>
        <div>
          <BlueButton
            variant="contained"
            className="filter__header__button newAdModalButton"
            onClick={handleSubmit}
            title={t("client.adsProfile.apply")}
            loading={loading}
          />
        </div>
      </Box>

      <section className="filter__priceRange">
        <Box className="filter__priceRange__minimum">
          <label className="filter__priceRange__minimum__label">
            From Price
          </label>
          <input
            name="from_price"
            type="text"
            className="filter__priceRange__minimum__input"
            placeholder="from Price"
            value={filterValues["from_price"]}
            onChange={(e) => handleChange(e.target.value, e.target.name)}
          />
        </Box>
        <Box className="filter__priceRange__maximum">
          <label className="filter__priceRange__maximum__label">To Price</label>
          <input
            name="to_price"
            type="text"
            className="filter__priceRange__maximum__input"
            placeholder="To Price"
            value={filterValues["to_price"]}
            onChange={(e) => handleChange(e.target.value, e.target.name)}
          />
        </Box>
      </section>
      {demographics != null && (
        <>
          <GreenMultiSelect
            label={t("providers.myProfile.language")}
            options={demographics?.languages}
            name="language_ids"
            value={filterValues.language_ids}
            handleChange={handleChange}
          />

          <GreenMultiSelect
            label={t("providers.s.myProfile.ethnicity")}
            options={demographics?.ethnicities}
            name="ethnicity_ids"
            value={filterValues.ethnicity_ids}
            handleChange={handleChange}
          />

          <GreenMultiSelect
            label={t("client.adsProfile.workLocation")}
            options={demographics?.work_locations}
            name="work_location_ids"
            value={filterValues.work_location_ids}
            handleChange={handleChange}
          />

          <GreenMultiSelect
            label={t("client.adsProfile.workSurface")}
            options={demographics?.work_surfaces}
            name="work_surface_ids"
            value={filterValues.work_surface_ids}
            handleChange={handleChange}
          />

          <GreenMultiSelect
            label={t("client.adsProfile.sexualOrientation")}
            options={demographics?.sexual_orientations}
            name="sexual_orientation_ids"
            value={filterValues.sexual_orientation_ids}
            handleChange={handleChange}
          />

          <GreenMultiSelect
            label={t("client.adsProfile.smokingFrequency")}
            options={demographics?.smoking_frequencies}
            name="smoking_frequency_ids"
            value={filterValues.smoking_frequency_ids}
            handleChange={handleChange}
          />

          <GreenMultiSelect
            label={t("client.adsProfile.drugFrequancy")}
            options={demographics?.drug_frequencies}
            name="drug_frequency_ids"
            value={filterValues.drug_frequency_ids}
            handleChange={handleChange}
          />
        </>
      )}
    </main>
  );
};

export default Filter;
