import React from "react";
import {
  Favorite as FavoriteIcon,
  Star as StarIcon,
  QuestionAnswer as QuestionAnswerIcon,
} from "@mui/icons-material";
import { Rating, Box } from "@mui/material";

const ProviderProfile = ({ profile }) => {
  return (
    <aside className="clientPorfile__profile">
      <figure className="clientPorfile__profile__image">
        <figcaption className="clientPorfile__profile__image__box">
          <img
            src={
              profile?.provider.profile_image ||
              require("../../../../../../assets/body-werx-1.jpeg")
            }
            alt="body-wrex-profile-image"
          />
        </figcaption>
        <div className="clientPorfile__profile__image__heartIcon">
          <FavoriteIcon />
        </div>
      </figure>
      <summary className="clientPorfile__profile__name">
        <h4 className="clientPorfile__profile__name__title">
          {profile?.provider.screen_name}
        </h4>
        <section>
          <aside className="clientPorfile__profile__name__heartIcon"></aside>
          <aside className="clientPorfile__profile__name__messageIcon">
            <QuestionAnswerIcon />
          </aside>
        </section>
      </summary>
      <section className="clientPorfile__profile__rating">
        <aside className="clientPorfile__profile__rating__starIcon">
          <Rating
            name="read-only"
            value={profile.provider.review_ratings}
            readOnly
          />
        </aside>
      </section>
      {/* <h5 className="clientPorfile__profile__information__text clientPorfile__profile__information">
        5'10 | 150 lbs | 29 years old
      </h5> */}
    </aside>
  );
};

export default ProviderProfile;
