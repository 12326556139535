import React from "react";

const GreenInput = ({
  name,
  label,
  type,
  icon,
  placeholder,
  value,
  required,
  handleChange,
  disabled,
  register,
  field,
  step,
  error,
  readOnly
}) => {
  return (
    <>
      {error ? (
        <section className="greenInput" style={{ border: "1px solid red" }}>
          <label>{label}</label>
          <aside className="greenInput__inputBox">
            <icon className="greenInput__inputBox__icon">{icon}</icon>
            <input
              onChange={(e) => handleChange(e)}
              name={name}
              type={type}
              placeholder={placeholder}
              value={value}
              required={required}
              disabled={disabled}
              step={step}
              {...field}
              {...register}
            />
          </aside>

        </section>
      ) : (
        <section className="greenInput" style={{ border: "1px solid #87C85B" }}>
          <label>{label}</label>
          <aside className="greenInput__inputBox">
            <icon className="greenInput__inputBox__icon">{icon}</icon>
            <input
              onChange={(e) => handleChange(e)}
              name={name}
              type={type}
              placeholder={placeholder}
              value={value}
              required={required}
              disabled={disabled}
              step={step}
              {...field}
              {...register}
              readOnly={readOnly}
            />
          </aside>
        </section>
      )}
    </>
  );
};

export default GreenInput;
