import React, { useState } from "react";
import ProviderAuth from "../../../apis/ProviderAuth";
import SignupForm from "./SignupForm";

export default function ProviderSignup() {
  const { providerSignup } = ProviderAuth();

  const [provider, setprovider] = useState({
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    dob: null,
    screen_name: null,
  });

  const onSubmit = async (data) => {
    await providerSignup(data);
  };

  return (
    <SignupForm user={provider} setUser={setprovider} onSubmit={onSubmit} />
  );
}
