import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Call as CallIcon } from "@mui/icons-material";

export default function CopyPhoneButton({ phone }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
  };

  return (
    <CopyToClipboard text={phone} onCopy={handleCopy}>
      <Tooltip title={copied ? "Copied!" : "Copy Phone Number"}>
        <IconButton>
          <CallIcon />
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  );
}
