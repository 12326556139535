import React, { useState } from "react";
import ClientAuth from "../../../apis/ClientAuth";
import SigninForm from "./SigninForm";

export default function ClientSignin() {
  const { clientSignin } = ClientAuth();

  const [client, setClient] = useState({
    email: null,
    password: null,
  });

  const onSubmit = async (data) => {
    await clientSignin(data);
  };

  return (
    <SigninForm user={client} setUser={setClient} onSubmit={onSubmit} />
  );
}
