import React, { useState, useEffect } from "react";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import AdCard from "../../../../components/ProviderAd/AdCard";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import AdsApis from "../../../../apis/provider/AdsApis";
import Loading from "../../../../components/general/Loading";
import { useTranslation } from "react-i18next";

const Ads = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [ads, setAds] = useState([]);
  const { getAds, isAdLoading } = AdsApis();
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchAd = async () => {
    const response = await getAds();
    if (response.data) {
      setAds(response.data);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };
  useEffect(() => {
    fetchAd();
  }, []);

  if (errorMessage) return null;

  return (
    <main className="myAds">
      {isAdLoading ? (
        <div className="circularLoading">
          <Loading />
        </div>
      ) : (
        <section className="myAds__body">
          <header>
            <h2>{t("providers.myAd.adHeader")}</h2>
            <div className="myAds__body__container__adsContainer__addNewButton">
              <Button
                variant="contained"
                className="newAdModalButton"
                onClick={() => navigate("/provider/newAd")}
              >
                {t("providers.myAd.newAd")}
              </Button>
            </div>
          </header>
          <section className="myAds__body__container2">
            {ads.map((ad) => (
              <div className="myAds__body__container2__adsContainer2">
                <AdCard ad={ad} />
              </div>
            ))}
          </section>
          {ads.length == 0 && (
            <div className="myAds__body__container">
              <div className="myAds__body__container__adsContainer">
                <MessageOutlinedIcon className="myAds__body__container__adsContainer__adIcon" />
                <p>{t("providers.myAd.noAdd")}</p>
              </div>
            </div>
          )}
        </section>
      )}
    </main>
  );
};

export default Ads;
