import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import PackageApis from "../../../apis/provider/PackageApis";
import Loading from "../../../components/general/Loading";

export default function StripeCallback() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { createSubscriptionTransaction, isPackagesLoading } = PackageApis();

  const createSubscription = async () => {
    const redirect_status = searchParams.get("redirect_status");
    const payment_intent = searchParams.get("payment_intent");
    const payment_intent_client_secret = searchParams.get(
      "payment_intent_client_secret"
    );
    await createSubscriptionTransaction({
      ...params,
      redirect_status: redirect_status,
      payment_intent: payment_intent,
      payment_intent_client_secret: payment_intent_client_secret,
    });
  };

  useEffect(() => {
    createSubscription();
  }, []);

  if (isPackagesLoading) return <Loading />;
}
