import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function ProviderProfileSetupRoute({ children }) {
  const { provider, isproviderAuthenticated } = useSelector(
    (state) => state.providerAuth
  );
  const access_token = Cookies.get("provider-access-token");
  const navigate = useNavigate();

  if (true) {
    return children;
  } else if (
    isproviderAuthenticated &&
    access_token &&
    provider &&
    provider.active
  ) {
    navigate("/provider/profile");
    return;
  } else {
    navigate("/signin");
  }
}
