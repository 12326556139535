import React from "react";
import { useTranslation } from "react-i18next";
import ClientPage from "../../../../components/PageWrapper/ClientPage";

export default function TermsAndConditions() {
  const { t } = useTranslation();
  const currentDate = new Date().toLocaleString("en-US", {
    month: "long",
    year: "numeric",
  }); // Format date for display

  return (
    <ClientPage>
      <div className="termsConditions">
        <h1>{t("client.termsConditions.title")}</h1>
        <div className="termsConditions__content">
          <h2>
            {t("client.termsConditions.effectiveDate")}: {currentDate}
          </h2>
          <p>{t("client.termsConditions.introduction")}</p>

          <h2>{t("client.termsConditions.acceptance")}</h2>
          <p>{t("client.termsConditions.acceptanceDetail")}</p>

          <h2>{t("client.termsConditions.changes")}</h2>
          <p>{t("client.termsConditions.changesDetail")}</p>

          <h2>{t("client.termsConditions.useOfWebsite")}</h2>
          <div className="termsConditions__content__heading3">
            <h3>{t("client.termsConditions.eligibility")}</h3>
            <p>{t("client.termsConditions.eligibilityDetail")}</p>

            <h3>{t("client.termsConditions.accountCreation")}</h3>
            <p>{t("client.termsConditions.accountCreationDetail")}</p>

            <h3>{t("client.termsConditions.prohibitedActivities")}</h3>
            <p>{t("client.termsConditions.prohibitedActivitiesDetail")}</p>
          </div>

          <h2>{t("client.termsConditions.listingsContent")}</h2>
          <div className="termsConditions__content__heading3">
            <h3>{t("client.termsConditions.contentSubmission")}</h3>
            <p>{t("client.termsConditions.contentSubmissionDetail")}</p>

            <h3>{t("client.termsConditions.accuracy")}</h3>
            <p>{t("client.termsConditions.accuracyDetail")}</p>

            <h3>{t("client.termsConditions.contentReview")}</h3>
            <p>{t("client.termsConditions.contentReviewDetail")}</p>
          </div>

          <h2>{t("client.termsConditions.feesPayments")}</h2>
          <div className="termsConditions__content__heading3">
            <h3>{t("client.termsConditions.subscriptionPlans")}</h3>
            <p>{t("client.termsConditions.subscriptionPlansDetail")}</p>

            <h3>{t("client.termsConditions.refunds")}</h3>
            <p>{t("client.termsConditions.refundsDetail")}</p>
          </div>

          <h2>{t("client.termsConditions.disclaimers")}</h2>
          <div className="termsConditions__content__heading3">
            <h3>{t("client.termsConditions.noProfessionalAdvice")}</h3>
            <p>{t("client.termsConditions.noProfessionalAdviceDetail")}</p>

            <h3>{t("client.termsConditions.limitationOfLiability")}</h3>
            <p>{t("client.termsConditions.limitationOfLiabilityDetail")}</p>
          </div>

          <h2>{t("client.termsConditions.indemnification")}</h2>
          <p>{t("client.termsConditions.indemnificationDetail")}</p>

          <h2>{t("client.termsConditions.termination")}</h2>
          <p>{t("client.termsConditions.terminationDetail")}</p>

          <h2>{t("client.termsConditions.governingLaw")}</h2>
          <p>{t("client.termsConditions.governingLawDetail")}</p>

          <h2>{t("client.termsConditions.contact")}</h2>
          <p>{t("client.termsConditions.contactDetail")}</p>
        </div>
      </div>
    </ClientPage>
  );
}
