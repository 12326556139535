import React from "react";
import { Outlet } from "react-router-dom";
import ProviderNavbar from "../Navbar/ProviderNavbar";

const providerPage = () => {
  return (
    <div className="providerPage">
      <ProviderNavbar />
      <Outlet />
    </div>
  );
};

export default providerPage;
