import React from "react";
import { motion } from "framer-motion";

const pulseTransition = {
  duration: 0.8,
  repeat: Infinity,
  repeatType: "mirror",
  ease: "easeInOut",
};

const LoadingIcon = () => (
  <div
    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
  >
    <motion.div
      style={{
        width: 20,
        height: 20,
        margin: 5,
        borderRadius: "50%",
        backgroundColor: "#3498db",
      }}
      animate={{ scale: [1, 1.5, 1] }}
      transition={pulseTransition}
    />
    <motion.div
      style={{
        width: 20,
        height: 20,
        margin: 5,
        borderRadius: "50%",
        backgroundColor: "#3498db",
      }}
      animate={{ scale: [1, 1.5, 1] }}
      transition={{ ...pulseTransition, delay: 0.2 }}
    />
    <motion.div
      style={{
        width: 20,
        height: 20,
        margin: 5,
        borderRadius: "50%",
        backgroundColor: "#3498db",
      }}
      animate={{ scale: [1, 1.5, 1] }}
      transition={{ ...pulseTransition, delay: 0.4 }}
    />
  </div>
);

const TypingText = ({ text }) => {
  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.h1
      initial="hidden"
      animate="visible"
      transition={{ delay: 0.5, staggerChildren: 0.08 }}
      style={{
        display: "flex",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontSize: "2em",
      }}
    >
      {text.split("").map((char, index) => (
        <motion.span key={index} variants={variants}>
          {char}
        </motion.span>
      ))}
    </motion.h1>
  );
};

const DynamicText = ({ text }) => (
  <motion.div
    initial={{ scale: 1 }}
    animate={{ scale: [1, 1.2, 1] }}
    transition={{
      duration: 1.5,
      repeat: Infinity,
      repeatType: "mirror",
      ease: "easeInOut",
    }}
    style={{ fontSize: "3em", fontWeight: "bold" }}
  >
    {text}
  </motion.div>
);

export default function BodyWerxLoading() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        background: "#193D66",
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DynamicText text="Body Werx" />
      <LoadingIcon />
    </div>
  );
}
