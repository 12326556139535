import React, { createContext, useContext, useState } from "react";
import { Snackbar, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ToastContext = createContext();

export function useToast() {
  return useContext(ToastContext);
}

export function ToastProvider({ children }) {
  const [toast, setToast] = useState({ message: null, flag: null });
  const [open, setOpen] = useState(false);

  const showToast = (toast) => {
    setOpen(true);
    setToast({ ...toast });
  };

  const handleClose = () => {
    setOpen(false);
    setToast({ message: null, flag: null });
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toast.message && (
        <Snackbar
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          action={action}
          className="toastMessage"
        >
          <Alert
            onClose={handleClose}
            severity={
              toast.flag == "error"
                ? "error"
                : toast.flag == "success"
                ? "success"
                : ""
            }
            sx={{ width: "100%" }}
          >
            {toast.message}
          </Alert>
        </Snackbar>
      )}
    </ToastContext.Provider>
  );
}
