import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import ReviewsRating from "./Components/ReviewsRating";
import ClientPage from "../../../../components/PageWrapper/ClientPage";
import ReviewsApis from "../../../../apis/client/ReviewsApis";
import { useNavigate, useParams } from "react-router-dom";
import ProviderAds from "../../../../apis/client/ProviderAds";
import Loading from "../../../../components/general/Loading";
import { useToast } from "../../../../context/ToastContext";

const Review = () => {
  const { provider_slug } = useParams();
  const { isLoading, provider } = ProviderAds();
  const { isReviewLoading, addReview } = ReviewsApis();
  const [errorMessage, setErrorMessage] = useState(null);
  const { showToast } = useToast();

  const [review, setReview] = useState({
    client_id: 1,
    review: null,
    rating: 0,
    provider_slug: provider_slug,
  });

  const navigate = useNavigate();
  const [providerData, setproviderData] = useState(null);

  const postReview = async () => {
    if (review.review < 12 && review.rating != null) {
      showToast({ message: "Please fill all the fields", flag: "error" });
      return;
    }
    const response = await addReview(review);
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      navigate(`/provider/${provider_slug}/portfolio`);
    }
  };

  const fetchprovider = async () => {
    const response = await provider(provider_slug);
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      setproviderData(response.data);
    }
  };

  useEffect(() => {
    fetchprovider();
  }, []);

  if (isLoading || isReviewLoading) return <Loading />;
  if (errorMessage) return null;

  return (
    <ClientPage>
      {providerData && (
        <div className="reviews">
          <div className="review__container">
            <p className="review__container__title">Post a review</p>
            <img
              src={
                providerData.profile_image ||
                require("../../../../assets/Profile_Image.jpg")
              }
              alt=""
              className="review__container__profileImage"
            />
            <h4 className="review__container__name">
              {providerData.screen_name}
            </h4>
            <aside className="review__container__services">
              <div className="review__container__services__offers">
                {providerData.provider_detail.services.map((service) => (
                  <span>{service.name}</span>
                ))}
                {providerData.provider_detail.massage_types.map((massage) => (
                  <span>{massage.name}</span>
                ))}
              </div>
            </aside>
            <div className="review__container__ratings">
              <p>Rate your experience</p>
              <div className="review__container__ratings__stars">
                <ReviewsRating rating={review.rating} setReview={setReview} />
              </div>
            </div>
            <div className="review__container__review">
              <p>Review</p>
              <textarea
                rows={5}
                onChange={(e) =>
                  setReview({ ...review, review: e.target.value })
                }
              />
            </div>

            <Button
              variant="contained"
              className="review__container__postButton"
              onClick={postReview}
            >
              Post review
            </Button>
          </div>
        </div>
      )}
    </ClientPage>
  );
};

export default Review;
