import React, { useState } from "react";
import ModalForm from "./ModalForm";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Edit as EditIcon } from "@mui/icons-material";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(25px)",
      color: "#193d66",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#ffffff" : "#ffffff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#e8e8e8" : "#e8e8e8",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function EditServiceHourModal({ data, setRefetch, refetch }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const [serviceHour, setServiceHour] = useState({ ...data });

  return (
    <div>
      <EditIcon
        onClick={handleOpen}
        sx={{ position: "relative", top: ".3rem" }}
      />

      <ModalForm
        serviceHour={serviceHour}
        setServiceHour={setServiceHour}
        open={open}
        setOpen={setOpen}
        setRefetch={setRefetch}
        refetch={refetch}
      />
    </div>
  );
}
