import axios from "axios";
import { useLoadScript } from "@react-google-maps/api";
import { useSelector } from "react-redux";

const libraries = ["Places"];

export default function GoogleMap() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_MAP_API_KEY, // Replace with your API key
    libraries,
  });

  const { latitude, longitude } = useSelector((state) => state.currentLocation);

  const getGeoCoding = async (lat, lng) => {
    const apiKey = process.env.GOOGLE_MAP_API_KEY; // Replace with your API key
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

    return await axios
      .get(url)
      .then((response) => {
        return {
          data: response.data.results[0].formatted_address,
          error: null,
        };
      })
      .catch((err) => {
        return {
          data: null,
          error: "Not fount locaiton",
        };
      });
  };

  const getReverseGeoCoding = (address) => {
    if (!isLoaded) return;
    try {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === "OK") {
          return {
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          };
        } else {
          return {
            latitude: latitude,
            longitude: longitude,
          };
        }
      });
    } catch (err) {
    }
  };

  return {
    getReverseGeoCoding,
    getGeoCoding,
  };
}
