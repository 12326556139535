import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { store } from "../../redux/store";
import { SERVER_API } from "../../helpers/variable";
import Cookies from "js-cookie";
import { onSuccess, onError, formDataToObject } from "./request";
import { providerData } from "../../redux/slices/auth/provider";
import { useSelector } from "react-redux";

export default function PackageApis() {
  const [isPackagesLoading, setIsPackagesLoading] = useState(false);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { language } = useSelector((state) => state.i18n);

  async function getPackages() {
    setIsPackagesLoading(true);
    return await axios
      .get(`${SERVER_API}/providers/packages?locale=${language.id}`, {
        headers: {
          Authorization: Cookies.get("provider-access-token"),
        },
      })
      .then((response) => response)
      .then((response) => {
        setIsPackagesLoading(false);
        onSuccess(response, showToast, "Data fetched!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsPackagesLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function getPaymentIntent(id, price) {
    setIsPackagesLoading(true);
    return await axios
      .get(
        `${SERVER_API}/providers/packages/${id}/stripe_payment_intent?price=${price}&locale=${language.id}`,
        {
          headers: {
            Authorization: Cookies.get("provider-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        setIsPackagesLoading(false);
        onSuccess(response, showToast, "Payment Intent is fetched!");
        return {
          data: response.data,
          errorMessage: null,
        };
      })
      .catch((error) => {
        setIsPackagesLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  async function createSubscriptionTransaction(params) {
    setIsPackagesLoading(true);
    return await axios
      .post(
        `${SERVER_API}/providers/packages/${params.package_id}/subscription?locale=${language.id}`,
        params,
        {
          headers: {
            Authorization: Cookies.get("provider-access-token"),
          },
        }
      )
      .then((response) => response)
      .then((response) => {
        setIsPackagesLoading(false);
        onSuccess(response, showToast, "Subscribed successfully!");
        if (
          response.data.active &&
          response.data.initial_profile_setup == "completed" &&
          response.data.subscribed
        ) {
          store.dispatch(
            providerData({
              provider: response.data,
            })
          );
          navigate("/provider/profile");
        } else {
          navigate(`/provider/profileSetup/subscription`);
        }
      })
      .catch((error) => {
        setIsPackagesLoading(false);
        onError(error, showToast);
        return {
          data: null,
          errorMessage: error.response.data.message,
        };
      });
  }

  return {
    getPackages,
    getPaymentIntent,
    createSubscriptionTransaction,
    isPackagesLoading,
  };
}
