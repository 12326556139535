import React, { useState } from "react";
import PhoneVerification from "../../profileSetup/PhoneVerification";
import ProfileSetup from "../../../../apis/client/ProfileSetup";

export default function ClientPhoneVerificaiton() {
  const [code, setCode] = useState(null);
  const { isLoading, phoneVerification, resendVerification } = ProfileSetup();

  const handlePhoneVerification = async () => {
    await phoneVerification(code);
  };

  return (
    <PhoneVerification
      code={code}
      setCode={setCode}
      handleSubmit={handlePhoneVerification}
      resendVerificationApi={resendVerification}
      isLoading={isLoading}
    />
  );
}
