import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  package_id: 0,
  amount: 0,
  frequency: 0,
  active: false,
  expire_date: null,
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubscription(state, action) {
      return action.payload;
    },
  },
});

export const { setSubscription } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
