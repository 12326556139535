import React, { useState } from "react";
import ProviderAuth from "../../../apis/ProviderAuth";
import SigninForm from "./SigninForm";

export default function ProviderSignin() {
  const { providerSignin } = ProviderAuth();

  const [provider, setprovider] = useState({
    email: null,
    password: null,
  });

  const onSubmit = async (data) => {
    await providerSignin(data);
  };

  return (
    <SigninForm user={provider} setUser={setprovider} onSubmit={onSubmit} />
  );
}
